.l-course-intro {
  &__list {
    padding-top: $padding__block;

    position: relative;
    &::before {
      @include pesudo("");
      height: 100%;
      width: $height__line;

      background: $color__line;
      left: calc($width__deco-dot / 2);
      transform: translateX(-50%);
    }
  }
  &__item {
    padding-left: $width__deco-line;
    margin-bottom: $margin__block;
  }
  &__deco {
    @include pesudo("");
    @include square($width__deco-dot, 50%);

    top: 50%;
    left: auto;
    right: calc(100% + $width__deco-line - $width__deco-dot);
    transform: translateY(-50%);
    &[data-color="false"] {
      background: $color--secondary;
      color: $color__font--light;
    }
    &[data-color="true"] {
      background: $color--accent;
      color: $color__font--light;
    }
    &::after {
      @include pesudo("");
      width: calc($width__deco-line - $width__deco-dot);
      height: $height__line;

      background: $color__line;

      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
  }
}
