.l-summer-camp {
  $_: &;
  @mixin summer-color($type: action, $property: background) {
    @if ($type == action) {
      @each $i in (1, 2, 3) {
        &[data-summer="#{$i}"] {
          #{$property}: map-get(map-get($color, summer), $i);
        }
      }
    } @else if ($type == bg) {
      @each $i in (1, 2, 3) {
        &[data-summer="#{$i}"] {
          #{$property}: map-get(map-get($color, opacity), $i);
        }
      }
    }
  }
  @mixin gird-name($name) {
    &[data-grid="#{$name}"] {
      grid-area: #{$name};
    }
  }
  $radius__btn: 10rem;
  $radius__block: 0.5rem;
  $radius__mark: 0.25rem;
  $weight__stress: 500;
  $spec__mobile: $lg;
  position: relative;
  overflow: hidden;
  &__title {
    &__bg {
      margin-right: -0.15rem;

      font-weight: bold;
      font-size: 10vw;
      line-height: 150%;
      letter-spacing: 0.15em;
      text-align: center;

      @include summer-color(bg, color);
      position: relative;
      @media screen and (max-width: $spec__mobile) {
        margin-bottom: $margin__section;
        font-size: 15vw;
      }
    }
    &__text {
      @include heading(h3);
      color: $color--primary;
      text-align: center;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media screen and (max-width: $spec__mobile) {
        width: 80%;
        line-height: 160%;
      }
    }
  }
  &__icon {
    width: 100%;
    aspect-ratio: 1 / 1;

    background: $color__opacity--primary;
    border-radius: 50%;

    @include text(normal, 400);
    color: $color__font--subtext;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__img {
    @include square(5rem, 0);
  }
  &__seg {
    width: 100%;
    min-width: 100%;
    margin-bottom: 1rem;
  }
  &__wrapper {
    width: 100%;
    overflow-x: auto;
    @media screen and (max-width: $spec__mobile) {
      overflow-x: visible;
    }
  }
  &__table {
    width: 100%;
    @media screen and (min-width: $spec__mobile) and (max-width: $xl) {
      min-width: max-content;
    }
  }
  &__row {
    width: 100%;
    min-width: max-content;
    padding: 1rem 0;
    grid-template-columns: 1.5fr 1.5fr 1fr 1.5fr 1fr 1fr;
    grid-template-areas: "title time location price tutor action";

    border-radius: $radius__block;
    &--head {
      padding: 0;

      border-radius: $radius__block $radius__block 0 0;

      @include summer-color(action, background);
      @media screen and (max-width: $spec__mobile) {
        display: none;
      }
      #{$_}__col {
        font-weight: $weight__stress;
      }
    }
    &[data-row="odd"] {
      background: $color__opacity--primary;
    }
    @media screen and (max-width: $spec__mobile) {
      padding: 1rem;
      grid-template-columns: 1.8fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        "title tutor"
        "time location"
        "time action";
    }
  }
  &__col {
    @include text(normal, 400);
    white-space: nowrap;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @each $i in ("title", "time", "location", "price", "tutor", "action") {
      @include gird-name($i);
    }
    @media screen and (max-width: $spec__mobile) {
      @each $i in ("location", "tutor", "action") {
        &[data-grid="#{$i}"] {
          align-items: flex-end;
        }
      }
      align-items: flex-start;
      &[data-grid="price"] {
        display: none;
      }
    }
  }
  &__mark {
    padding: $padding__btn;

    @include summer-color(action, background);
    border-radius: $radius__mark;
    box-shadow: $shadow__action;

    color: inherit;
    font-weight: $weight__stress;
    @include interaction(btn);
  }
  &__btn {
    @include summer-color(action, background);
    color: $color__font--text;
    border-radius: $radius__btn;
    box-shadow: $shadow__action;

    @include interaction(btn);
    @media screen and (max-width: $spec__mobile) {
      background: $color--primary !important;

      color: $color__font--light;
    }
  }
  $radius__block: 1rem;
  &__swiper {
    &__item {
      height: 100%;
      img {
        border-radius: $radius__block;
      }
    }
    &__action {
      display: none;
    }
  }
  &__block {
    width: 50vw;
    height: auto;
    position: absolute;
    z-index: -2;
    @each $i in (1, 2, 3, 4, 5, 6) {
      &[data-block="#{$i}"] {
        @if ($i % 2 != 0) {
          left: 0;
          right: auto;
          transform: translateX(-50%);
        } @else {
          right: 0;
          left: auto;
          transform: translateX(50%);
        }
        top: calc((100% / 4) * ($i - 1) + 15rem);
        @if ($i == 6) {
          top: auto;
          bottom: 0;
        }
        @media screen and (max-width: $sm) {
          width: 80vw;
          top: calc((100% / 6) * ($i - 1) + 8%);
          @if ($i == 6) {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }
  &__text {
    @include text(normal, 400);
    color: $color__font--text;
  }
  &__subtitle {
    @include heading(h5);
    line-height: 1.5;

    color: $color__font--text;
  }
  &__btn {
    border-radius: $radius--infinity;
    color: color(font, light);
  }
  &__heading {
    &__title {
      @include heading(h3);
      line-height: 1.5;
      color: $color--primary;
    }
    &__wrapper {
      // aspect-ratio: 2 / 1;
    }
    &__img {
      &[data-img="1"],
      &[data-img="4"] {
        height: 40%;
      }
      &[data-img="2"],
      &[data-img="3"] {
        height: calc(60% - 1rem);
      }
      &[data-img="2"],
      &[data-img="4"] {
        margin-top: 1rem;
      }
    }
  }
  &__course {
    &__tab {
      border-radius: $radius--infinity;
      transition: $transition;
      &.active {
        border: 1px solid transparent;
      }
      @media screen and (max-width: $md) {
        padding: 0.5rem;
      }
      @each $i in (1, 2, 3) {
        &[data-style="#{$i}"] {
          border: 1px solid color(font, summer, $i);
          &:hover {
            border: 1px solid color(font, summer, $i);
            background: color(font, summer, $i);
          }
          &.active {
            @if ($i) ==1 {
              color: color(font, light);
            } @else {
              color: color(font, text);
            }
            background-color: color(font, summer, $i);
          }
        }
      }
    }
    &__card {
      width: 100%;
      height: 100%;

      border-radius: $radius__block;
      box-shadow: map-get($map: map-get($shadow, summer), $key: block);

      position: relative;
      overflow: hidden;

      transition: $transition;
      &:hover {
        box-shadow: map-get($map: map-get($shadow, summer), $key: block);
      }
      &__cover {
        width: 100%;
        height: 10rem;

        background: linear-gradient(90deg, #fceecc 0%, #fde7db 100%);
      }
      &__img {
        height: 4rem;
      }
      &__content {
        height: calc(100% - 10rem);
        padding: $padding__block;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &__btn {
        background: color(font, summer, 1);
        border-radius: $radius--infinity;
        color: color(light);
      }
      &__strong {
        @include heading(h4);
        color: color(font, summer, 1);
      }
    }
  }
  &__feature {
    &__img {
      width: 8rem;
      aspect-ratio: 1 / 1;
      background: color(opacity, summer, 2);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      width: 50%;
      aspect-ratio: 1 / 1;
    }
  }
  &__time {
    &__calendar {
      width: 100%;
    }
    &__card {
      width: 100%;
      height: max-content;
      padding: $padding__block;

      border-radius: $radius__block;
      &[data-color="1"] {
        background: #ebf0f5;
      }
      &[data-color="2"] {
        background: #fde7db;
      }
      &[data-color="3"] {
        background: #fceecc;
      }
      &[data-color="4"] {
        background: #c8d4ec;
      }
    }
    &__btn {
      border-radius: $radius--infinity;
      color: color(font, text);
      &:hover {
        color: color(font, text);
      }
      &[data-color="1"] {
        background: #bbcede;
      }
      &[data-color="2"] {
        background: #f58849;
      }
      &[data-color="3"] {
        background: #f5c54d;
      }
      &[data-color="4"] {
        color: color(font, light);
        background: #466ebe;
      }
    }
  }
  &__qa {
    &__accordion {
    }
    &__item {
      @include clear(btn);

      border: none;
      background: transparent;

      transition: $transition;
    }
    &__toggle {
      @include clear(btn);

      @include text(normal, 400);
      padding: $padding__block;
      background: $color--light;
      border-radius: $radius__block !important;
      box-shadow: map-get($map: map-get($shadow, summer), $key: item);

      display: flex;
      align-items: center;
      &:hover,
      &:focus,
      &:active {
        background: #fde7db;
        box-shadow: map-get($map: map-get($shadow, summer), $key: item);
      }
      &:not(.collapsed) {
        background: #fde7db;
        box-shadow: map-get($map: map-get($shadow, summer), $key: item);
        border-radius: $radius__block !important;

        color: $color__font--text;
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
      }
    }
    &__title {
      width: max-content;
      padding: 0.5rem 1rem;

      background: color(font, summer, 1);
      border-radius: $radius--infinity;

      @include text(normal, 500);
      color: color(light);
    }
    &__content {
      padding: $padding__accordion;

      border-radius: 0 0 $radius__block $radius__block;
      background: color(light);
      box-shadow: map-get($map: map-get($shadow, summer), $key: block);
    }
    &__wrapper {
      padding-top: 0;
    }
  }
  &__picture {
    &__img {
      height: 100%;
    }
  }
  &__deco {
    width: max-content;
    position: absolute;
    z-index: -1;
    @each $i in (1, 2, 3, 4, 5) {
      &[data-deco="#{$i}"] {
        transform: scale(0.5);
        @if ($i % 2 != 0) {
          bottom: -25%;
          top: auto;
          right: -10%;
          left: auto;
          @media screen and (max-width: $sm) {
            right: -25%;
          }
        } @else {
          top: 0;
          bottom: auto;
          left: -10%;
          right: auto;
          @media screen and (max-width: $sm) {
            left: -35%;
          }
        }
        @if ($i == 5) {
          top: auto;
          bottom: -10%;
          @media screen and (max-width: $md) {
            bottom: -20%;
            right: -50%;
          }
        }
      }
    }
  }
  &__carousel {
    &__swiper {
      height: 500px;
    }
    &__item {
      border-radius: $radius__block;
      transition: 0.3s;
      overflow: hidden;
      img {
        transition: none;
      }
      &.active {
        overflow: visible;
        z-index: 1;
        img {
          width: 200% !important;
          border-radius: $radius__block;
          transform: translateX(-25%);
        }
      }
      &:not(.active) {
        height: 50%;
        transform: translateY(50%);
      }
    }
    &__action {
      @include clear(btn);
      @include square(2rem, 50%);
      background: #f0ac0180;
      border: $height__line solid color(light);

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 50%;
      z-index: 2;
      transition: 0.3s;
      &.prev {
        left: 15%;
      }
      &.next {
        right: 15%;
      }
      &:hover,
      &:focus,
      &:active {
        border: $height__line solid color(light);
        background: #f0ac01;
      }
      &:disabled {
        opacity: 0.5;
      }
      @media screen and(max-width:$sm) {
        display: none;
      }
    }
  }
}
