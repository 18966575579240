.l-student-feedback {
  $_: &;
  background-image: url("../img/student-feedback/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  &__item {
    margin-bottom: $margin__section;
  }
  &__text {
    text-align: center;
  }
  &__stress {
    @include text(normal, 500);
    color: $color--primary;
  }
  &__block {
    padding: $padding__card;

    background: $color--light;
    border-radius: $radius__block;
    box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.15);

    position: relative;
    &::before {
      @include pesudo("");
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 18px 24px 18px;
      border-color: transparent transparent $color--light transparent;

      top: -1.125rem;
    }
    &[data-left="true"] {
      &::before {
        left: 1.125rem;
      }
    }
    &[data-left="false"] {
      &::before {
        left: auto;
        right: 1.125rem;
      }
    }
  }
  &__heading {
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
  }
  $width: 20rem;
  &__wrapper {
    width: $width;
    height: calc($width - 4rem);
    min-width: $width;

    position: relative;

    &[data-left="true"] {
      #{$_}__deco {
        left: 60%;
        transform: translateX(-50%) rotate(10deg);
      }
    }
    &[data-left="false"] {
      #{$_}__deco {
        left: 40%;
        transform: translateX(-50%) rotate(-10deg);
      }
    }
    @media screen and (max-width: $lg) {
      width: 100%;
      height: $width;
      min-width: auto;
      #{$_}__deco {
        left: 50% !important;
      }
    }
  }
  &__deco {
    @include pesudo("");
    width: $width;
    height: $width;

    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;

    top: auto;
    bottom: 2rem;

    transition: $transition;
    @media screen and (max-width: $lg) {
      bottom: 0;
      background-position: center;
      transform: translateY(-50%);
    }

    &::before {
      @include pesudo("");
      @include square(100%, 0);
      background-color: $color--gray3;

      opacity: 0;
      transition: $transition;
    }
    &::after {
      @include pesudo("\f002");
      font-size: 3rem;
      color: $color--gray3;

      opacity: 0;
      transition: $transition;
    }
    &:hover {
      &::before {
        opacity: 0.1;
        // filter: blur(4rem);
      }
      &::after {
        opacity: 1;
      }
    }
  }
  &__icon {
    @include square(2rem, 0);
    position: relative;
    &::after {
      @include pesudo("\f101");
      color: $color--primary;
      @media screen and (max-width: $md) {
        @include pesudo("\f103");
      }
    }
  }
  &__tag {
    color: $color__font--light;
    &[data-odd="true"] {
      background: $color--secondary;
    }
    &[data-odd="false"] {
      background: $color--accent;
    }
  }
  &__date {
    min-width: 100px;
    margin-right: $margin__block;

    display: flex;
    align-items: center;
  }
  &__marquee {
    margin-bottom: 5rem;
    padding-bottom: $padding__block;

    position: relative;
    &::after {
      @include pesudo("");
      width: 100%;
      height: $height__line;

      background: $color--gray5;

      top: auto;
    }
  }
  &__content {
    margin-bottom: 0;
    white-space: pre-line;
  }
}

.c-marquee {
  $width__action: 3rem;
  width: 100%;

  position: relative;
  &__actions {
    margin-left: $margin__block;
    display: flex;
    align-items: center;
    @include cursor(pointer);
  }
  &__action {
    @include clear(btn);
    @include square($width__action, 50%);
    min-width: $width__action;

    transition: 0.3s;
    &:hover {
      background: $color--gray7;
    }
    &:focus,
    &:active {
      background: $color--gray6;
    }
  }
  &__text {
    @include text(normal, 400);
  }
}
