@-webkit-keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes gradient-flow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes gradient-flow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient-flow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-5rem);
    transform: translateY(-5rem);
  }
}
@keyframes slide-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-5rem);
    transform: translateY(-5rem);
  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(-5rem);
    transform: translateY(-5rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    -webkit-transform: translateY(-5rem);
    transform: translateY(-5rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-down {
  0% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-down {
  0% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shine {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  80% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.e-ani {
  &--slide-up {
    -webkit-animation: slide-up 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-up 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &--slide-down {
    -webkit-animation: slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &--fade-in {
    -webkit-animation: fade-in 0.2s ease-in-out both;
    animation: fade-in 0.2s ease-in-out both;
  }
  &--fade-out {
    -webkit-animation: fade-out 0.2s ease-in-out both;
    animation: fade-out 0.2s ease-in-out both;
  }
  &--fade-in-left {
    -webkit-animation: fade-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &--fade-in-right {
    -webkit-animation: fade-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &--fade-in-down {
    -webkit-animation: fade-in-down 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-in-down 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@keyframes ice-shine {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
