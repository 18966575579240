.c-course {
  &-nav {
    $_: &;

    width: 100%;
    padding: 0 1rem;
    height: 4rem;
    background: $color--light;
    box-shadow: $shadow__course-nav;

    display: flex;
    align-items: center;
    justify-content: center;

    &__list {
      overflow-x: auto;
      @include clear("scrollbar");
    }
    &__item {
      padding: 0.5rem 2rem;
      @include cursor(pointer);
      @include interaction(underline);
      white-space: nowrap;

      position: relative;
      &::before {
        width: 1.5rem;
        background: $color--secondary;
      }
      &:hover {
        &::before {
          width: calc(80% - 2rem);
        }
      }
      &.active {
        &::before {
          @include pesudo("");
          width: 1.5rem;
          height: $height__line;
          border-radius: 1rem;

          top: auto;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          opacity: 1;
        }
      }
      @media screen and (max-width: $sm) {
        padding: 0.5rem 1rem;
      }
    }
    &__link {
      @include heading(h6);
      color: $color--primary;
      &:hover {
        color: $color--primary;
      }
    }
    &.active {
      box-shadow: $shadow__nav;
    }
  }
  &-card {
    $_: &;
    padding: $padding__card;

    border-radius: $radius__block;
    background: $color--light;
    box-shadow: $shadow__course-nav;

    @media screen and (max-width: $sm) {
      padding: 1rem;
    }
    &__title {
      padding: 0.25rem 1rem;

      background: $color--primary;
      border-radius: $radius__card-tag;
      box-shadow: $shadow__card-tag;

      @include text(normal, bold);
      color: $color__font--light;
      text-align: start;

      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
    }
    &__text {
      @include text(normal, "");
      color: $color__font--text;
    }
    &__highlight {
      margin-bottom: 1rem;
      @include heading(h4);
      color: $color--primary;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__subtitle {
      @include text(normal, 500);
      color: $color--error;
    }
    &__hr {
      margin: 1rem 0;
    }
    &--sticky {
      position: sticky;
      top: $height__nav + 1.5rem;
      padding: 0;
      #{$_}__img {
        width: 100%;
        height: 8rem;

        border-radius: 0.5rem 0.5rem 0 0;

        overflow: hidden;

        position: relative;
      }
      #{$_}__content {
        padding: 2rem;
      }
    }
  }
}

.l-course {
  padding-bottom: 3.75rem;
  background: $color--gray7;
  &__action {
    margin-top: 1rem;
    @media screen and (max-width: $md) {
      margin: 1rem auto;
    }
  }
  &__img {
    width: 80%;
    @media screen and (max-width: $lg) and (min-width: $md) {
      width: 100%;
    }
    @media screen and (max-width: $md) and (min-width: $sm) {
      width: 80%;
    }
    @media screen and (max-width: $sm) {
      width: 100%;
    }
  }
}

.l-precaution {
  &__list {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
