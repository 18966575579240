:root {
  --color--primary: rgb(70, 110, 190); // #466ebe
  --color--secondary: rgb(190, 171, 235); // #d7b9e6
  --color--hover: #738ae8;
  --color--active: #5a6cb5;
  --color--light: #ffffff;
  --color--accent: rgb(121, 176, 234); // #a5d7eb
  --color--error: #d7639a;
  --color--stress: rgba(70, 110, 190, 0.2);

  --color--summer1: rgb(255, 167, 146);
  --color--summer2: #f0ac01;
  --color--summer3: rgb(128, 231, 255);
  --color__font--summer1: #f58849;
  --color__font--summer2: #f58849;
  --color__font--summer3: #bbcede;

  --color--gray1: rgba(51, 51, 51, 1); //#333333
  --color--gray2: rgba(80, 80, 80, 1); //#505050
  --color--gray3: rgba(130, 130, 130, 1); //#828282
  --color--gray4: rgba(189, 189, 189, 1); //#bdbdbd
  --color--gray5: rgba(224, 224, 224, 1); //#e0e0e0
  --color--gray6: rgba(242, 242, 242, 1); //#f2f2f2
  --color--gray7: rgba(250, 250, 250, 1); //#fafafa

  --color__opacity--primary: rgba(70, 110, 190, 0.1);
  --color__opacity--secondary: rgba(190, 171, 235, 0.1);
  --color__opacity--accent: rgba(121, 176, 234, 0.1);
  --color__opacity--hover: rgba(55, 145, 53, 0.2);
  --color__opacity--dark: rgba(0, 0, 0, 0.5);
  --color__opacity--light: rgba(255, 255, 255, 0.7);
  --color__opacity--gradient: linear-gradient(220deg, rgba(70, 110, 190, 0.7), rgba(190, 171, 235, 0.7));

  --color__opacity--summer1: rgba(255, 167, 146, 0.3);
  --color__opacity--summer2: #f0ac0133;
  --color__opacity--summer3: rgba(128, 231, 255, 0.3);

  --color__shadow: rgba(0, 0, 0, 0.1);
  --color__shadow--active: rgba(0, 0, 0, 0.2);
  --color__shadow--dropdown: rgba(0, 0, 0, 0.3);

  --color__font--text: var(--color--gray1);
  --color__font--subtext: var(--color--gray2);
  --color__font--muted: var(--color--gray3);
  --color__font--light: var(--color--light);

  --color__link: rgb(59, 160, 200);

  --color__bg--banner: linear-gradient(270deg, var(--color--primary), var(--color--secondary), var(--color--accent));
  --color__bg--accent: rgba(242, 247, 253, 1);

  --color__gradient--light: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  --color__gradient--main: linear-gradient(
    270deg,
    var(--color--primary),
    var(--color--secondary),
    var(--color--accent)
  );
  --color__gradient--shine: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.5) 55%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  --color__gradient--flow: linear-gradient(
    300deg,
    var(--color--primary) 0%,
    var(--color--accent) 45%,
    var(--color--light) 50%,
    var(--color--accent) 55%,
    var(--color--primary) 100%
  );
  --color__gradient--double: linear-gradient(
    45deg,
    var(--color--secondary),
    var(--color--accent),
    var(--color--secondary),
    var(--color--accent)
  );
  --num__btn: 0;
}
