.u {
  &-home-section {
    padding: 3rem 0;
    @media screen and (max-width: $lg) {
      padding: 2rem 0;
    }
    @each $i in(1, 2, 3, 4, 5) {
      @if $i % 2 != 0 {
        &[data-section="#{$i}"] {
          background: $color__opacity--accent;
        }
      } @else {
        background: transparent;
      }
    }
  }
}

.l-home {
  &__about {
    padding-top: 0;
  }
}

.l-key-visual {
  $_: &;
  width: 100%;
  height: 100vh;

  position: relative;
  @media screen and (max-width: $lg) {
    height: 42rem;
  }
  &::before {
    @include pesudo("");
    width: 100%;
    height: 18rem;

    background: $color__gradient--light;
    top: auto;
    bottom: -0.0625rem;
    z-index: 1;
  }
  .react-parallax-bgimage {
    width: 100%;
    height: 100% !important;

    object-fit: cover;
  }
  .react-parallax-content {
    width: 100%;
    height: max-content;

    position: absolute !important;
    bottom: 3.75rem;
    left: 0;
    z-index: 10;
  }
  &__img {
    width: 100%;
    height: 100%;

    position: relative;
  }
  &__bg {
    width: 100%;
    height: 100%;
    background-image: url("/img/home/pic/key-visual--tablet.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-attachment: fixed;

    position: relative;
    @media screen and (max-width: $sm) {
      background-image: url("/img/home/pic/key-visual--vertical.jpg");
    }
    #{$_}__slogan {
      width: 100%;

      position: absolute;
      bottom: 3rem;
      z-index: 10;
    }
  }

  &__text {
    @include key-visual-font;

    background: $color--primary;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transform: skew(-15deg);

    position: relative;
    &::before {
      width: 100%;
      @include pesudo(attr(data-text));
      @include key-visual-font;

      background: $color__gradient--shine;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200%;

      mix-blend-mode: screen;
      animation: shine 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    }
    &:first-of-type {
      margin-right: 0.5rem;
    }
    @media screen and (max-width: $sm) {
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
}

.l-courses {
  $_: &;
  $width__icon: 3rem;
  &__card {
    height: 100%;
  }
  &__control {
    margin-bottom: 3rem;

    display: flex;
    align-items: center;
  }
  &__btn {
    @include clear("btn");
    @include square($width__icon + 1rem, 0);
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    transition: $transition;
    &::before {
      @include pesudo("");
      width: max-content;
      @include text(small, 400);
      color: $color__font--subtext;

      top: 100%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
    &.active {
      #{$_}__icon {
        @include square($width__icon + 0.5rem, 50%);

        background: $color--secondary;
        &::after {
          color: $color__font--light;
        }
      }
    }
    &:hover {
      &.active {
        #{$_}__icon {
          background: $color--primary;
        }
      }
      #{$_}__icon {
        @include square($width__icon + 0.5rem, 50%);

        background: $color--gray4;

        position: relative;
        &::after {
          @include pesudo("");
          color: $color__font--light;
          font-size: 1.5rem;

          transition: $transition;
        }
      }
    }
    &[data-stage="enrolling"] {
      &::before {
        content: "招生中";
      }
      #{$_}__icon {
        &::after {
          @include pesudo("\f133");
        }
      }
    }
    &[data-stage="progressing"] {
      &::before {
        content: "開課中";
      }
      #{$_}__icon {
        &::after {
          @include pesudo("\f06d");
        }
      }
    }
    &[data-stage="coming-soon"] {
      &::before {
        content: "即將開課";
      }
      #{$_}__icon {
        &::after {
          @include pesudo("\f0ae");
        }
      }
    }
  }
  &__icon {
    @include square($width__icon, 50%);
    min-width: $width__icon;
    background: $color--light;

    position: relative;
    transition: $transition;
    &::after {
      color: $color--gray3;
      font-size: 1.25rem !important;
    }
  }
  &__deco {
    width: 3rem;
    height: $height__line;

    background: $color--gray5;
  }
}

.l-faculty {
  $_: &;
  &--card {
    $margin__section: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    #{$_}__avatar {
      width: 100%;
      aspect-ratio: 1 / 1;
      margin-right: 1rem;

      border-radius: 50%;
      overflow: hidden;
    }
    #{$_}__title {
      @include text(large, bold);
      color: $color--primary;
    }
    #{$_}__subtitle {
      @include text(small, "");
      color: $color__font--muted;
    }
    #{$_}__section {
      margin-bottom: $margin__section;
      &:last-of-type {
        margin-bottom: 0;
      }
      @media screen and (max-width: $lg) {
        margin-bottom: $margin__section--mobile;
      }
    }
    #{$_}__deco {
      width: 100%;
      height: 100%;

      background: $color--light;
      border-radius: $radius__card;
      box-shadow: $shadow__card;

      position: absolute;
      top: 0;
      z-index: -1;

      transition: $transition;
    }
    #{$_}__card {
      padding: $padding__card;

      box-shadow: $shadow__card;
      border-radius: $radius__card;
      background: $color--light;
      @media screen and (max-width: $sm) {
        padding: $padding__card--mobile;
      }
    }
    #{$_}__action {
      width: 100%;
      padding: $padding__card;

      background: $color--light;
      border-radius: 0 0 $radius__card $radius__card;

      position: absolute;
    }
    #{$_}__wrapper {
      position: relative;
      &:hover {
        #{$_}__deco {
          box-shadow: $shadow__card--active;
        }
      }
    }
    #{$_}__col {
      &.active {
        @media screen and (max-width: $lg) {
          margin-bottom: 6.75rem;
        }
        #{$_}__card {
          padding-bottom: 0;
          border-radius: $radius__card $radius__card 0 0;
        }
        #{$_}__deco {
          height: calc(100% + 6.75rem);
          box-shadow: $shadow__card--active;
        }
      }
    }
    #{$_}__tags {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &--wrapper {
    @mixin style__action() {
      height: $height__btn;

      border: $border__input;
      border-radius: $radius__btn;

      @include text(normal, 400);
      color: $color__font--subtext;

      @include cursor(pointer);

      transition: $transition;
      &:hover,
      &:focus,
      &:active {
        border: $border__input;
      }
    }
    @mixin style__action--active() {
      @include gradient-bg(double);
    }
    #{$_}__avatar {
      @include square(100%, $radius__block);
      overflow: hidden;

      position: relative;
    }
    #{$_}__card {
      padding: $padding__card;

      box-shadow: $shadow__card;
      border-radius: $radius__card;
      background: $color--light;
      @media screen and (max-width: $sm) {
        padding: $padding__card--mobile;
      }
    }
    #{$_}__section {
      margin-bottom: $margin__block;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    #{$_}__action {
      width: 100%;

      padding: $padding__btn;
      margin-bottom: $margin__item;

      @include style__action;

      display: flex;
      align-items: center;
      justify-content: space-between;

      position: relative;
      &:last-of-type {
        margin-bottom: 0;
      }
      &::after {
        @include pesudo("\f061");
        color: $color__font--muted;

        transition: $transition;

        left: auto;
        right: 1rem;
      }
      &:hover {
        @include style__action--active;
        &:after {
          color: $color__font--light;

          right: 0.5rem;
        }
      }
    }
    $width__deco: 6.625rem;
    $height__deco: 3rem;
    @mixin style__deco() {
      @include pesudo("");
      @include square(0, 0);
      border-style: solid;
      border-width: $width__deco $height__deco 0 0;

      left: 100%;
      top: 0;
    }
    #{$_}__info {
      padding: 1rem 2rem;
      background: $color--light;

      display: flex;
      flex-direction: column;
      align-items: center;

      position: absolute;
      left: 1rem;
      bottom: 1rem;
      z-index: 1;
      &::before {
        @include pesudo("");
        @include square(100%, 0);

        background: $color--light;

        z-index: -1;
      }
      &::after {
        @include style__deco();
        border-color: $color--light transparent transparent transparent;

        z-index: -1;
      }
      @media screen and (max-width: $sm) {
        padding: calc($padding__card--mobile / 2) $padding__card--mobile;
        &::after {
          border-width: 5.625rem 2rem 0 0;
        }
      }
    }
    #{$_}__deco {
      @include pesudo("");
      width: 100%;
      height: 100%;

      background: $color--primary;

      top: -0.75rem;
      left: 1.125rem;
      z-index: -2;
      &::after {
        @include style__deco();
        border-color: $color--primary transparent transparent transparent;

        z-index: -2;
      }
      @media screen and (max-width: $sm) {
        padding: calc($padding__card--mobile / 2) $padding__card--mobile;
        &::after {
          border-width: 5.625rem 2rem 0 0;
        }
      }
    }
    #{$_}__title {
      margin-bottom: $margin__item;

      @include text(normal, bold);
      color: $color--primary;
      line-height: 150%;
    }
    #{$_}__seg {
      margin-right: $margin__item;
      &:last-of-type {
        margin-right: 0;
      }
    }
    #{$_}__text {
      @include text(small, "");
      color: $color__font--muted;
      line-height: 150%;

      display: block;
    }
    #{$_}__control {
      width: 100%;
      margin-top: $margin__block;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    $height__btn: 2.75rem;
    #{$_}__icon {
      $width__block: $height__btn + 1rem;
      @include clear(btn);
      @include square($width__block, 0);
      min-width: $width__block;

      border-radius: $radius__btn;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: $transition;
      &:hover,
      &:focus {
        background: $color--gray6;
        @media screen and (max-width: $md) {
          background: transparent;
        }
      }
    }
    #{$_}__btn {
      @include clear(btn);
      width: 10rem;
      height: $height__btn;
      padding: $padding__btn;
      margin-right: 2rem;

      @include style__action;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover,
      &:focus {
        background: $color--gray6;
      }
      &.active {
        @include style__action--active;
        color: $color__font--light;
      }
    }
    #{$_}__tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

.l-features {
  &__block {
    margin-bottom: 2.75rem;
    @media screen and (max-width: $lg) {
      margin-bottom: 2rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__title {
    line-height: 200%;
  }
  &__img {
    width: 100%;
    height: 100%;

    overflow: hidden;
  }
}

.l-feedback {
  $_: &;
  &__card {
    $_: &;
    background: $color--light;
    box-shadow: $shadow__card;
    border-radius: $radius__card;

    overflow: hidden;

    transition: $transition;
  }
  &__content {
    padding: 2rem;
    @media screen and (max-width: $md) {
      padding: 1rem;
    }
  }
  &__avatar {
    width: 6rem;
    aspect-ratio: 1 / 1;
    margin-right: 1rem;

    border-radius: 50%;

    overflow: hidden;
  }
  &__title {
    @include text(medium, bold);
    color: $color--primary;
    text-align: start;
  }
  &__subtitle {
    @include text(small, "");
    color: $color__font--muted;
  }
  &__text {
    height: calc(100% - 7rem);
    margin-bottom: 0;

    @include text(normal, "");
    color: $color__font--subtext;
    text-align: start;

    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar;
  }
  &__swiper {
    padding: $padding__swiper;
    @media screen and (max-width: $md) {
      display: none;
    }
  }
  &__accordion {
    @media screen and (min-width: $md) {
      display: none;
    }
  }
  @media screen and (max-width: $md) {
    $line-height--mobile: 160%;
    .c-accordion {
      &__toggle {
        padding: 1rem;
        &::after {
          left: auto;
          right: $margin__accordion-arrow--mobile;
        }
      }
    }
    &__avatar {
      width: 4rem;
    }
    &__title {
      @include text(normal, bold);
      line-height: $line-height--mobile;
    }
    &__subtitle {
      @include text(small, 400);
      line-height: $line-height--mobile;
    }
  }
}

.l-carousel {
  &__swiper {
    margin-bottom: $margin__block;
    height: max-content;
    &__action {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @media screen and(max-width: $lg) {
        display: none;
      }
    }
    &__item {
      height: auto;
      border-radius: $radius__block;
      overflow: hidden;
    }
  }
}
