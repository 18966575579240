$spec__mobile: $lg;
$font-family__key-visual: GenSenRounded, "Inter", "Noto Sans TC", sans-serif;

$spring-course-2025-color: (
  main: (
    1: #eb9797,
    2: #82b1c8
  ),
  bg: (
    1: #edbcb94d,
    2: #c8d8e066
  ),
  text: (
    1: #c26060,
    2: #2f7394
  ),
  head: (
    1: #faebea,
    2: #c8d8e0
  ),
  row: (
    1: #faebea80,
    2: #c8d8e080
  )
);

.l-spring-course-2025 {
  $_: &;
  overflow: hidden;
  @mixin color($type, $property) {
    @each $i in (1, 2) {
      &[data-style="#{$i}"] {
        #{$property}: map-get(map-get($spring-course-2025-color, $type), $i);
      }
    }
  }
  &__section {
    @media screen and (max-width: $spec__mobile) {
      margin-bottom: $margin__section--mobile;
    }
  }
  &__title {
    &__bg {
      width: 100%;
      margin-right: -0.15rem;

      font-weight: bold;
      font-size: 10vw;
      line-height: 150%;
      letter-spacing: 0.15em;
      text-align: center;

      @include color(bg, color);
      position: relative;
      @media screen and (max-width: $spec__mobile) {
        margin-bottom: 0;
        font-size: 15vw;
      }
    }
    &__text {
      @include heading(h3);
      @include color(text, color);
      text-align: center;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media screen and (max-width: $spec__mobile) {
        width: 80%;
        line-height: 160%;
      }
    }
  }
  &__heading {
    margin-bottom: 5rem;
    color: $color-winter--primary;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title {
      position: relative;
      &__text {
        @include heading(h4);
      }
      &__deco {
        width: 120%;
        height: 1.25rem;

        background-image: url("../img/winter-camp-2025/title-deco.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }
    }
    &__subtitle {
      @include text(medium, 500);
    }
    @media screen and (max-width: $spec__mobile) {
      margin-bottom: 2rem;
    }
  }
  &__key-visual {
    margin-top: $height__nav;
    &__wrapper {
      width: 100%;
      height: calc(70dvh - $height__nav);

      background-image: url("../img/spring-course-2025/key-visual/bg.jpg");
      background-size: cover;
      background-position: center;

      border-radius: 0 0 10rem 0;
      box-shadow: 8px 10px 10px 0px $color--shadow;
      overflow: hidden;

      position: relative;

      color: $color--light;
      @media screen and (max-width: $spec__mobile) {
        height: calc(100dvh - $height__nav);
        border-radius: 0;
      }

      &::before {
        @include pesudo("");
        width: 100%;
        height: 4px;
        background: $color--light;

        top: 1rem;
      }
      &::after {
        @include pesudo("");
        width: 100%;
        height: 4px;
        background: $color--light;

        top: auto;
        bottom: 1rem;
      }
    }
    &__title {
      font-family: $font-family__key-visual;
      font-weight: 600;
      font-size: 3.75rem;
      letter-spacing: 0.25rem;
      text-align: center;

      @media screen and (max-width: $sm) {
        font-size: 2.5rem;
      }
    }
    &__subtitle {
      padding: 0.25rem 1.5rem;
      @include text(large, 500);
      font-family: $font-family__key-visual;
      letter-spacing: 0.125rem;

      background: #bb823f;
      border-radius: 10000rem;
    }
    &__text {
      @include text(large, 500);
      font-family: $font-family__key-visual;
    }
    &__icon {
      @include square(1.25rem, 0);
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 1rem;

      @media screen and (max-width: $sm) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }
    }
    &__tags {
      width: max-content;
      padding: 0.5rem 1.5rem;

      background: #bb823f;
      border-radius: 10000rem;

      align-self: center;
    }
    &__tag {
      @include text(normal, 500);
      line-height: 150%;
      font-family: $font-family__key-visual;

      background: #bb823f;
    }
    &__divider {
      width: 2px;
      height: 100%;
      background: $color--light;
    }
  }
  &__feature {
    &__row {
      padding: 5rem 0;
      @media screen and (max-width: $spec__mobile) {
        padding: 0;
      }
    }
    &__block {
      @include square(9rem, 0);

      border-radius: 50%;
      background: map-get($map: map-get($spring-course-2025-color, main), $key: 2);

      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      @include square(3.75rem, 0);
    }
    &__title {
      @include heading(h5);
      color: map-get($map: map-get($spring-course-2025-color, text), $key: 2);
      text-align: center;
    }
    &__content {
      @include text(normal, 400);
      color: map-get($map: map-get($spring-course-2025-color, text), $key: 2);
      text-align: center;
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      @media screen and (max-width: $spec__mobile) {
        display: none;
      }
      &--center {
        height: 24rem;

        background-image: url("../img/spring-course-2025/feature/deco--center.png");
        background-position: left;

        top: 50%;
        left: 0;
        z-index: -1;
        transform: translate(-12.5%, -50%);
      }
      &--bg {
        width: 100%;
        height: 100%;

        background: map-get($map: map-get($spring-course-2025-color, bg), $key: 2);

        top: 0;
        left: 0;
        z-index: -2;
      }
    }
  }
  &__course {
    &__block {
      height: 100%;
      padding: $padding__block;

      background: $color--light;
      box-shadow: 4px 4px 10px 0px #0000001a;
      border-radius: $radius__block;

      position: relative;
    }
    &__bg {
      width: 100%;
      height: 100%;

      @include color(bg, background);
      border-radius: $radius__block;

      position: absolute;
      left: -1rem;
      top: -1rem;
      z-index: -1;
    }
    &__icon {
      width: auto;
      height: 4.5rem;
    }
    &__title {
      @include heading(h4);
      @include color(main, color);
    }
    &__content {
      @include text(normal, 400);
      color: $color-winter--primary;
      text-align: center;
    }
    &__action {
      border-radius: 1000rem;
      @include color(main, background);
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      z-index: 1;
      @media screen and (max-width: $spec__mobile) {
        display: none;
      }
      &--top {
        height: 16rem;

        background-image: url("../img/spring-course-2025/course/deco--top.png");
        background-position: left;

        top: 0;
        left: -5%;
        z-index: -1;
      }
      &--bottom {
        height: 8.5rem;

        background-image: url("../img/spring-course-2025/course/deco--bottom.png");
        background-position: bottom right;

        bottom: -10%;
        right: -2.5%;
        z-index: -1;
      }
    }
  }
  @mixin gird-name($name) {
    &[data-grid="#{$name}"] {
      grid-area: #{$name};
    }
  }
  &__schedule {
    &__table {
      width: 100%;
      @media screen and (min-width: $spec__mobile) and (max-width: $xl) {
        min-width: max-content;
      }
    }
    &__row {
      width: 100%;
      min-width: max-content;
      padding: 1rem 0;
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1fr 1.5fr 1fr 1fr;
      grid-template-areas:
        "title date location price tutor action"
        "subtitle weekday location earlyPrice tutor action"
        "subtitle time location groupPrice tutor action"
        "subtitle totalTime location groupPrice tutor action";

      border-radius: $radius__block;
      &[data-row="odd"] {
        @include color(row, background);
      }
      &--head {
        height: 4.5rem;
        padding: 0;

        border-radius: $radius__block;

        grid-template-areas: "course time location price tutor action";
        @media screen and (max-width: $spec__mobile) {
          display: none;
        }
        #{$_}__schedule__col {
          font-weight: $weight__stress;
          @include color(text, color);
          justify-content: center;
        }
        @include color(head, background);
      }
      @media screen and (max-width: $spec__mobile) {
        margin-bottom: 1rem;
        padding: 1rem;
        grid-template-columns: auto auto 1fr;
        grid-template-rows: 1.5fr 1fr 1fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr;
        grid-template-areas:
          "title title title"
          "date date date"
          "weekday time time"
          "totalTime totalTime totalTime"
          "location location location"
          "tutor tutor tutor"
          "price price price"
          "earlyPrice earlyPrice earlyPrice"
          "groupPrice groupPrice groupPrice"
          "action action action";
        &[data-row="even"] {
          background: map-get($map: map-get($spring-course-2025-color, bg), $key: 2);
        }
      }
    }
    &__col {
      @include text(normal, 400);
      white-space: pre-line;
      text-align: center;
      color: $color-winter--primary;
      text-wrap: wrap;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @each $i in ("course", "time", "location", "price", "tutor", "action") {
        @include gird-name($i);
      }
      @each $i
        in (
          "title",
          "subtitle",
          "date",
          "weekday",
          "time",
          "totalTime",
          "location",
          "price",
          "earlyPrice",
          "groupPrice",
          "tutor",
          "action"
        )
      {
        @include gird-name($i);
      }
      @media screen and (max-width: $spec__mobile) {
        text-align: start;
        align-items: flex-start;
        justify-content: flex-start;
        &[data-grid="action"] {
          align-items: flex-end;
          justify-content: flex-end;
        }
        &[data-grid="title"],
        &[data-grid="subtitle"] {
          @include text(medium, 500);
        }
        &[data-grid="date"],
        &[data-grid="weekday"] {
          margin-right: 1rem;
          width: max-content;
        }
        &[data-grid="time"],
        &[data-grid="totalTime"] {
          width: 100%;
        }
      }
    }
    &__action {
      border-radius: 1000rem;
      @include color(main, background);
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      @media screen and (max-width: $spec__mobile) {
        display: none;
      }
      &--top {
        height: 16rem;

        background-image: url("../img/spring-course-2025/schedule/deco--top.png");
        background-position: right;

        top: -7.5%;
        right: 0;
        z-index: -1;
      }
    }
  }
  &__qa {
    padding-bottom: 0.5rem;
    position: relative;
    &__item {
      @include clear(btn);

      border: none;
      background: transparent;

      transition: $transition;
    }
    &__toggle {
      @include clear(btn);

      padding: $padding__block;
      background: $color--light;
      border-radius: $radius__block !important;
      box-shadow: map-get($map: map-get($shadow, summer), $key: item);

      @include text(normal, 500);
      color: $color-winter--primary;

      display: flex;
      align-items: center;
      &:hover,
      &:focus,
      &:active {
        @include color(main, background);
        color: $color--light;
        box-shadow: map-get($map: map-get($shadow, summer), $key: item);
      }
      &:not(.collapsed) {
        @include color(main, background);
        color: $color--light;
        box-shadow: map-get($map: map-get($shadow, summer), $key: item);
        border-radius: $radius__block !important;
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
      }
    }
    &__title {
      width: max-content;
      padding: 0.5rem 1rem;

      background: $color-winter--secondary;
      border-radius: $radius--infinity;

      @include text(normal, 500);
      @include color(main, background);
      color: $color--light;
    }
    &__text {
      @include text(normal, 400);
      color: $color-winter--primary;
    }
    &__content {
      padding: $padding__accordion;

      border-radius: 0 0 $radius__block $radius__block;
      background: color(light);
      box-shadow: map-get($map: map-get($shadow, summer), $key: block);
    }
    &__wrapper {
      padding-top: 0;
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      @media screen and (max-width: $spec__mobile) {
        display: none;
      }
      &--bg {
        height: 40rem;

        background-image: url("../img/spring-course-2025/qa/deco--bg.png");
        background-position: right;

        bottom: 0;
        right: -10%;
        z-index: -1;
      }
    }
  }
  &__carousel {
    padding-bottom: 15rem;
    &__swiper {
      height: 500px;
    }
    &__item {
      border-radius: $radius__block;
      transition: 0.3s;
      overflow: hidden;
      img {
        transition: none;
      }
      &.active {
        overflow: visible;
        z-index: 1;
        img {
          width: 200% !important;
          border-radius: $radius__block;
          transform: translateX(-25%);
        }
      }
      &:not(.active) {
        height: 50%;
        transform: translateY(50%);
      }
    }
    &__action {
      @include clear(btn);
      @include square(2rem, 50%);
      background: #dff1f280;
      border: $height__line solid color(light);

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 50%;
      z-index: 2;
      transition: 0.3s;
      &.prev {
        left: 15%;
      }
      &.next {
        right: 15%;
      }
      &:hover,
      &:focus,
      &:active {
        background: #dff1f280;
        border: $height__line solid color(light);
      }
      &:disabled {
        opacity: 0.5;
      }
      @media screen and(max-width:$sm) {
        display: none;
      }
    }
    &__bg {
      width: 100%;
      height: 100%;

      background-image: url("../img/spring-course-2025/carousel/bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}
