$color-winter--primary: #054781;
$color-winter--secondary: #2e6ba4;
$color-winter--light: #f5fafe;
$color-winter__font--light: #e5f2fa;
$color-winter__bg: #89bae3;
$color-winter__shadow: rgba(
  $color: $color-winter--primary,
  $alpha: 0.5
);
.c-particle {
  height: 100%;
  position: relative;
  &__heading {
    padding: $padding__card;

    background: rgba($color: #fff, $alpha: 0.5);
    border-radius: $radius__card;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    margin-bottom: 1rem;
    font-family: GenSenRounded, "Inter", "Noto Sans TC", sans-serif;
    font-weight: 700;

    color: $color-winter--primary;
    white-space: nowrap;
    @media screen and (min-width: $xxl) and (max-width: $xxxl) {
      font-size: 4rem;
    }
    @media screen and (min-width: $xxxl) {
      font-size: 6rem;
    }
  }
  &__subtitle {
    margin-bottom: 0;

    @include text(large, 500);
    font-family: GenSenRounded, "Inter", "Noto Sans TC", sans-serif;

    color: #054781;
  }
  &__fg {
    width: 100%;
    height: 100%;

    background-image: url("../img/winter-camp/kv.png");
    background-position: bottom center;
    background-repeat: repeat-x;
    background-size: cover;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &__bg {
    width: 100%;
    height: 100%;

    background-color: $color-winter--light;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
}

.l-winter-camp {
  $_: &;
  &__key-visual {
    height: 40vh;
    margin-top: 4rem;
    @media screen and (max-width: $lg) {
      height: 20vh;
    }
  }
  &__card {
    $__: &;
    &__item {
      width: 100%;
      height: 35vh;
      padding: $padding__card $padding__block 4rem $padding__block;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      position: relative;

      @include cursor(pointer);
      transition: 0.3s;
      &[data-index="odd"] {
        background-color: $color-winter--secondary;
        #{$__}__icon {
          background-color: $color-winter--primary;
        }
      }
      &[data-index="even"] {
        background-color: $color-winter--primary;
        #{$__}__icon {
          background-color: $color-winter--secondary;
        }
      }
      &:hover {
        background-color: transparent;
        #{$_}__card__bg {
          &::before {
            animation: ice-shine 1s infinite;
          }
        }
        #{$_}__card__title,
        #{$_}__card__text {
          color: $color-winter--primary;
        }
      }
      @media screen and (max-width: $md) {
        height: max-content;
        padding: $padding__block;
      }
      @media screen and (min-width: $xxl) and (orientation: landscape) {
        height: 100%;
      }
      @media screen and (orientation: portrait) {
        height: 100%;
        justify-content: flex-start;
      }
    }
    &__bg {
      @include square(100%, 0);
      position: absolute;
      top: 0;
      left: 0;

      background-image: url("https://previews.123rf.com/images/docer2000/docer20002006/docer2000200600307/149791601-frozen-winter-window-glass-background-ice-pattern.jpg");
      background-size: cover;

      overflow: hidden;

      opacity: 0.5;
      &::before {
        @include pesudo("");
        width: 200%;
        height: 100%;
        background: linear-gradient(
          45deg,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(255, 255, 255, 0) 60%,
          rgba(255, 255, 255, 0) 100%
        );
        mix-blend-mode: screen;

        top: 0;
        left: 0;
      }
      z-index: -1;
    }
    &__icon {
      @include square(3rem, 50%);
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
    &__title {
      margin-bottom: 0.5rem;

      @include heading(h5);
      font-family: GenSenRounded, "Inter", "Noto Sans TC", sans-serif;
      color: $color-winter__font--light;
    }
    &__text {
      @include text(normal, 400);
      color: $color-winter__font--light;
    }
  }
  $height__schedule: 90vh;
  $opacity__snow: 0.3;
  $width__main: 60rem;
  &__main {
    width: 100%;
    height: max-content;
    min-height: $height__schedule;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    &::before {
      @include pesudo("");
      @include square(100%, 0);

      background-image: url("../img/winter-camp/snow-bg.png");
      background-size: contain;
      background-repeat: repeat;
      background-position: center center;

      opacity: $opacity__snow;
      z-index: -1;
    }
    &::after {
      @include pesudo("");
      @include square(100%, 0);

      background-color: $color-winter__bg;

      z-index: -2;
    }
    &__content {
      width: 58rem;
      height: max-content;
      min-height: calc($height__schedule - 5vh);
      padding: $padding__card;
      padding-top: 4rem;

      position: relative;
      &::after {
        @include pesudo("");
        @include square(100%, 0);
        height: calc(100% + 1rem);

        background: $color-winter--light;
        border-radius: $radius__card;

        top: -1rem;
        z-index: -1;
        @media screen and (max-width: $width__main) {
          border-radius: 0;
        }
      }
      &::before {
        @include pesudo("");
        width: auto;
        height: 9rem;
        aspect-ratio: 6.67 / 1;

        background-image: url("../img/winter-camp/snow-deco.png");
        background-repeat: no-repeat;

        top: -4rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        @media screen and (max-width: $width__main) {
          display: none;
        }
      }
      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }
  }
  $width__icon: 4rem;
  $padding__icon: 0.75rem;
  $width__action: 3.5rem;
  $width__border: 0.5rem;
  $radius__accordion: 3rem;
  @mixin style__icon() {
    background: $color-winter--primary;
    box-shadow: $shadow__action;
    border: $width__border solid $color-winter--light;
  }
  &__schedule {
    &__item {
      margin-bottom: $margin__block;

      background: $color--light;
      border-radius: $radius__card;

      transition: $transition;
      @include cursor(pointer);
      &:hover {
        box-shadow: $shadow__card;
      }
    }
    &__top {
      padding: $padding__block 0 0 $padding__block;
      position: relative;
      z-index: 0;
      &::after {
        @include pesudo("");
        width: 100%;
        height: 2rem;
        background: $color-winter--primary;
        border-radius: $radius__card $radius__card 0 0;
        z-index: -1;
      }
    }
    &__icon {
      @include square($width__icon, 50%);
      padding: $padding__icon;
      @include style__icon;
    }
    &__content {
      padding: $padding__block;
      padding-top: 0.5rem;
    }
    &__avatar {
      margin-right: $margin__item;
      @include square(3rem, 50%);
      overflow: hidden;
    }
    &__title {
      @include text(medium, 500);
      color: $color__font--text;
    }
    &__text {
      @include text(normal, 400);
      color: $color__font--subtext;
      &--stress {
        @include text(medium, 500);
      }
    }
    &__btn {
      margin-right: $margin__item;
      background: $color-winter--primary;
      border-radius: $radius--infinity;
      color: $color__font--light;
      &:hover,
      &:focus,
      &:active {
        background: $color-winter--primary;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    &__tabs {
      margin-bottom: $margin__block;
    }
    &__tab {
      background: $color-winter__bg;
      border-radius: $radius--infinity;
      @include text(normal, 400);
      color: $color__font--light;

      transition: $transition;
      &:hover,
      &:focus,
      &:active {
        background: $color-winter--secondary;
      }
      &.active {
        background: $color-winter--primary;
        box-shadow: $shadow__action;
        color: $color__font--light;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  &__swiper {
    position: relative;
    &__title {
      @include pesudo("");
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }
    &__item {
      height: 40rem;
    }
    &__action {
      @include pesudo("");
      @include square($width__action, 50%);
      @include clear(btn);

      @include style__icon;
      color: $color__font--light;

      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      transition: $transition;
      &:hover {
        @include square($width__action + 1rem, 50%);
        background: $color-winter--secondary;
        border: 0.5rem solid $color-winter--light;
      }
      &:focus,
      &:active {
        @include square($width__action, 50%);
        background: $color-winter--secondary;
        border: 0.5rem solid $color-winter--light;
      }
      &.prev {
        left: 33.3%;
      }
      &.next {
        left: 66.6%;
      }
      @media screen and (max-width: $lg) {
        display: none;
      }
    }
    &__deco {
      $height__deco: 10rem;
      $offset__deco: -3rem;
      @include pesudo("");
      width: 110%;
      height: $height__deco;

      border-radius: 0 0 100% 100%;
      background-color: $color-winter__bg;
      box-shadow: 0 0rem 2rem 0 $color-winter__shadow;

      left: 50%;
      transform: translateX(-50%);

      z-index: 2;
      overflow: hidden;
      &::before {
        @include pesudo("");
        width: 100%;
        height: $height__schedule;

        background-image: url("../img/winter-camp/snow-bg.png");
        background-size: contain;
        background-repeat: repeat;

        opacity: $opacity__snow;
        z-index: 2;
      }
      &.top {
        top: $offset__deco;
        &::before {
          display: none;
        }
      }
      &.bottom {
        background-color: $color-winter__bg;
        top: auto;
        bottom: $offset__deco;

        transform: translateX(-50%) rotate(180deg);
        &::before {
          display: none;
        }
      }
      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }
    &__line {
      @include pesudo("");
      width: calc($width__border + 0.5rem);
      height: 100%;

      background: $color-winter__bg;
      box-shadow: 0 0 1rem 0 $color-winter__shadow;

      transform: translateX(-50%);
      z-index: 1;
      &.left {
        left: 33.3%;
      }
      &.right {
        left: 66.6%;
      }
      @media screen and (max-width: $lg) {
        display: none;
      }
    }
  }
  &__footer {
    width: 100%;
    height: 20vh;

    position: relative;
    &__fg {
      width: 100%;
      height: 100%;

      background-image: url("../img/winter-camp/footer.png");
      background-position: bottom center;
      background-repeat: repeat-x;
      background-size: contain;

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      @media screen and (max-width: $xl) {
        background-size: cover;
      }
    }
    &__bg {
      width: 100%;
      height: 100%;

      background-color: $color-winter__bg;

      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
    }
  }
  &__title {
    margin-bottom: $margin__block;

    font-family: GenSenRounded, "Inter", "Noto Sans TC", sans-serif;
    font-weight: 700;
    color: $color-winter--primary;
    font-size: 2.5rem;
    white-space: nowrap;

    display: flex;
    justify-content: center;
    @media screen and (min-width: $xxl) and (max-width: $xxxl) {
      font-size: 3rem;
    }
    @media screen and (min-width: $xxxl) {
      font-size: 3.5rem;
    }
  }
  &__qa {
    $_: &;
    &__accordion {
      margin-bottom: $margin__block;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__item {
      @include clear(btn);

      border: none;
      background: transparent;
      border-radius: $radius--infinity !important;

      transition: $transition;
    }
    &__toggle {
      @include clear(btn);

      @include text(normal, 400);
      padding: $padding__block;
      background: $color--light;
      border-radius: $radius--infinity !important;

      display: flex;
      align-items: center;
      &::after {
        display: none;
      }
      &:hover,
      &:focus,
      &:active {
        color: $color__font--light;
        background: $color-winter--secondary;
      }
      &:not(.collapsed) {
        background: $color-winter--primary;
        border-radius: $radius__accordion $radius__accordion 0 0;
        color: $color__font--light;
        &:hover {
          background: $color-winter--secondary;
        }
      }
      @media screen and (max-width: $sm) {
        border-radius: $radius__block !important;
      }
    }
    &__content {
      padding: $padding__block;

      @include text(normal, 400);
      display: flex;
      align-items: flex-start;

      white-space: pre-line;
    }
    &__icon {
      @include square(calc($width__icon - 1rem), 50%);
      aspect-ratio: 1 / 1;
      margin-right: 1rem;

      @include style__icon;
      @include text(normal, 500);
      color: $color__font--light;

      display: flex;
      justify-content: center;
      align-items: center;
      &[data-type="Q"] {
        background-color: $color-winter--primary;
      }
      &[data-type="A"] {
        color: $color-winter--primary;
        background-color: $color-winter--light;
        border-color: $color-winter--primary;
      }
    }
  }
  .e-icon {
    margin-top: 0.5rem;
    &--winter {
      color: $color-winter--secondary;
    }
  }
  .e-tag {
    border-radius: $radius--infinity;
  }
}
