.l-header {
  $_: &;
  width: 100%;
  height: $height__nav;
  padding: 0;

  box-shadow: $shadow__nav;

  position: fixed;
  top: 0;
  z-index: 1040;

  display: flex;
  align-items: center;
  &::after {
    @include pesudo("");
    width: 100%;
    height: 100%;

    background: $color--light;
    z-index: -1;
  }
  &__logo {
    height: 3rem;
  }
  &__list {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__item {
    height: 100%;
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__link {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;

    @include clear(btn);

    @include interaction(underline);
    @include heading(h6);
    color: $color--primary;

    display: flex;
    align-items: center;
    justify-content: center;

    @include cursor(pointer);

    position: relative;
    &::before {
      width: 1.5rem;
      background: $color--secondary;
      bottom: 0;
    }
    &:hover {
      &::before {
        width: calc(80% - 2rem);
      }
    }
    @media screen and (max-width: $spec__header) {
      &::before {
        display: none;
      }
      &:hover {
        background: $color--gray6;

        color: $color__font--text;
        &::before {
          display: none;
        }
      }
      &:focus,
      &:active {
        color: $color__font--text;
        background: $color__opacity--accent;
        box-shadow: $shadow__accordion;
        border: none;
      }
    }
  }
  &__top {
    height: $height__nav;
    min-height: $height__nav;
    padding: 0;

    z-index: 1022;
  }
  &__backdrop {
    z-index: 1021;

    background: $color__opacity--light;
    &.show {
      opacity: 1;
      .navbar-toggler-icon {
        &::after {
          @include pesudo("\f00d");
        }
      }
    }
  }
  &__offcanvas {
    width: 60%;
    height: 100%;

    flex-direction: row;
    justify-content: flex-end;
  }
  &__body {
    width: max-content;
    height: 100%;

    @media screen and (max-width: $spec__header) {
      width: 100%;
    }
  }
  &__toggle {
    width: 2.75rem;
    height: 2.75rem;
    padding: 0;

    border: none;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:focus,
    &:active {
      box-shadow: none;
    }
    .navbar-toggler-icon {
      width: 1.5rem;
      height: 1.5rem;

      background-image: none;
    }
  }
  &__icon {
    transition: $transition;
  }
}
@media screen and (max-width: $spec__header) {
  .offcanvas {
    width: 60%;

    border: none;
    box-shadow: $shadow__nav;
  }
}

.c-banner {
  $_: &;
  width: 100%;
  height: $height__banner;

  display: flex;
  align-items: center;
  justify-content: center;
  &__title {
    margin: 4rem 0 1rem 0;
    color: $color--light;
    text-align: center;
  }
  &__subtitle {
    color: $color--light;
    text-align: center;
  }
  &--default {
    @include gradient-bg(banner);
  }
  &--summer {
    @include gradient-bg(summer);
    #{$_}__title,
    #{$_}__subtitle {
      color: $color__font--text;
    }
  }
  &--winter-2025 {
    background-color: #d5ebf7;
    #{$_}__title,
    #{$_}__subtitle {
      color: $color__font--text;
    }
  }
  &--spring-course-2025 {
    background-color: #f0e0d9;
    #{$_}__title,
    #{$_}__subtitle {
      color: $color__font--text;
    }
  }
}

.l-footer {
  $_: &;
  height: $height__footer;
  padding: 2rem 0;

  background: $color__gradient--main;
  &__list {
    display: flex;
    align-items: center;
  }
  &__btn {
    &:hover {
      background: $color--light;
      #{$_}__icon {
        color: $color--primary;
      }
    }
    &:focus,
    &:active {
      background: $color--gray6;
      #{$_}__icon {
        color: $color--primary;
      }
    }
  }
  &__icon {
    margin: 0;
    @include text(large, "");
    color: $color__font--light;
  }
  &__item {
    margin: 0 0.5rem 0 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__text {
    margin-top: 1rem;

    @include text(small, "");
    color: $color__font--light;
    text-align: center;
    @media screen and (max-width: $sm) {
      margin-top: 0;
    }
  }
}

.l-not-found {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-image: url("../img/common/og.jpg");
  background-size: cover;
  // background-attachment: fixed;

  position: relative;
  // &::before {
  //   @include pesudo("");
  //   width: 100%;
  //   height: 20rem;

  //   background: $color__gradient--light;

  //   top: auto;
  //   bottom: 0;
  // }
  &__content {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
  &__title {
    @include key-visual-font;
    color: $color--primary;
    white-space: nowrap;
    transform: skew(-15deg);
    @media screen and (max-width: $sm) {
      margin-bottom: 0.5rem;
      font-size: 2.5rem;
    }
  }
  &__waviy {
    @include heading(h4);
    color: $color__font--light;
    line-height: 150%;
    text-align: center;

    position: relative;
    display: inline-block;

    animation: flip 5s infinite;
    animation-delay: calc(0.2s * var(--order__letter));
  }
  &__action {
    padding: 0.5rem 1rem;
    margin-top: 1rem;

    border-radius: $radius__btn;

    @include text(large, 500);
    @include cursor(pointer);
  }
}

.c-header {
  &-accordion {
    margin-bottom: 0;
    &__toggle {
      width: 100%;
      padding: 1rem;

      border-radius: 0 !important;
      &::after {
        left: auto;
        right: 1rem;
        color: $color--secondary;
      }
    }
    &__body {
      padding: 0;
    }
    &__item {
      width: 100%;
      margin: 0;
    }
    &__link {
      padding: 0.5rem 1rem;

      @include text(normal, 500);
      color: $color--primary;
      @include cursor(pointer);

      justify-content: flex-start;
      &--accordion {
        padding: 1rem;
        color: $color__font--text;
      }
    }
    &__title {
      color: $color__font--text;
    }
  }
}
