body {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 110%;
  color: $color__font--text;

  overflow: overlay;

  @include scrollbar;
}

h1 {
  @include heading(h1);
}

h2 {
  @include heading(h2);
}

h3 {
  @include heading(h3);
}

h4 {
  @include heading(h4);
}

h5 {
  @include heading(h5);
}

h6 {
  @include heading(h6);
}

p {
  margin-bottom: 0.5rem;

  @include text(normal, "");
}

a {
  @include cursor(pointer);
}

.u {
  &-page {
    padding: $padding__page;
    &--placeholder {
      min-height: 100vh;
    }
  }
  &-section {
    margin-bottom: $margin__section;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &-block {
    margin-bottom: $margin__block;
  }
  &-font {
    &--stress {
      @include text(normal, 400);
      color: $color__font--subtext;

      position: relative;
      z-index: 0;

      display: inline-block;
      &::after {
        @include pesudo("");
        width: 100%;
        height: 50%;

        background: $color--stress;

        top: auto;
        bottom: 0;
        z-index: -1;
      }
    }
  }
}

.e-img {
  width: 100%;
  height: 100%;
  &--contain {
    object-fit: contain;
  }
  &--cover {
    object-fit: cover;
  }
  &--top {
    object-fit: cover;
    object-position: top;
  }
}

.e-btn {
  @include cursor(pointer);
  &--text {
    width: max-content;
    padding: 0 1rem;
    height: 2.75rem;

    @include clear(btn);
    border-radius: $radius__btn;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--icon {
    width: 2.75rem;
    height: 2.75rem;

    @include clear(btn);
    border-radius: $radius__btn;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--tab {
    height: 2.75rem;
    padding: 0 1rem;

    @include clear(btn);
    @include text(normal, bold);
    color: $color__font--text;
  }
  &--primary {
    background: $color--primary;
    color: $color__font--light;
    @include interaction(btn);
  }
  &--secondary {
    background: $color--secondary;
    color: $color__font--light;
    @include interaction(btn);
  }
  &--opacity {
    border-radius: $radius__btn;
    @include interaction(btn--opacity);
  }
  &--w100 {
    width: 100%;
    height: $height__btn;

    border-radius: $radius__btn;
    @include interaction(btn);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--wmax {
    width: max-content;
    height: $height__btn;
    padding: 0.5rem 1rem;

    border-radius: $radius__btn;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--outline {
    border: 1px solid $color--primary;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid $color--primary;
    }
  }
  &--light {
    border-color: $color--light;
    color: $color__font--light;
  }
}

.e-icon {
  font-size: 1rem;
  &--primary {
    color: $color--primary;
  }
  &--secondary {
    color: $color--secondary;
  }
  &--right {
    margin-right: 0.5rem;
  }
  &--left {
    margin-left: 0.5rem;
  }
  &--both {
    margin: 0 0.5rem;
  }
  &--deco {
    color: $color--gray4;
  }
  &--light {
    color: $color__font--light;
  }
  &--muted {
    color: $color__font--muted;
  }
}

.e-link {
  color: $color__link;

  transition: $transition;
  &--left {
    margin-right: 0.5rem;
  }
  &--both {
    margin: 0 0.5rem;
  }
  &:hover {
    color: $color--hover;
  }
}

.e-tag {
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0.5rem 0 0;

  background: $color--gray6;

  @include text(small, "");
  color: $color__font--subtext;
  white-space: nowrap;

  border-radius: $radius__tag;

  display: inline-block;
  &--icon {
    width: max-content;

    position: relative;
    display: inline;
    &::before {
      @include pesudo("");
      width: 1.25rem;
      height: 1.25rem;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      top: 50%;
      left: 0.5rem;
      transform: translateY(-50%);
    }
    // Language & Tools
    &[data-tag="Python"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/python.svg");
      }
    }
    &[data-tag="Git"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/git.svg");
      }
    }
    &[data-tag="Bitbucket"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/bitbucket.svg");
      }
    }
    &[data-tag="Django"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/django.svg");
      }
    }
    &[data-tag="SQL"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/sql.png");
      }
    }
    &[data-tag="Numpy"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/numpy.svg");
      }
    }
    &[data-tag="Pandas"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/pandas.svg");
      }
    }
    &[data-tag="Matplotlib"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/matplotlib.svg");
      }
    }
    &[data-tag="Opencv"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/opencv.svg");
      }
    }
    &[data-tag="Pytorch"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/pytorch.svg");
      }
    }
    &[data-tag="Tensorflow"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/tensorflow.svg");
      }
    }
    &[data-tag="Scikit-learn"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/overview/icon/scikit-learn.svg");
      }
    }
    &[data-tag="Github"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/github.svg");
      }
    }
    &[data-tag="HackerRank"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/hackerrank.svg");
      }
    }
    &[data-tag="C"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/c.svg");
      }
    }
    &[data-tag="C++"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/cpp.svg");
      }
    }
    &[data-tag="Eclipse"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/eclipse.svg");
      }
    }
    &[data-tag="Pygame"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/pygame.svg");
      }
    }
    &[data-tag="Pycharm"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/pycharm.png");
      }
    }
    &[data-tag="Zerojudge"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/zerojudge.png");
      }
    }
    &[data-tag="Code::Blocks"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/codeblocks.png");
      }
    }
    &[data-tag="Midjourney"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/midjourney.svg");
      }
    }
    &[data-tag="ChatGPT"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/chatgpt.svg");
      }
    }
    &[data-tag="Leetcode"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/course/icon/leetcode.svg");
      }
    }

    // Feekback Tags
    &[data-tag="AI DeepLearning"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/home/icon/ai--icon.png");
      }
    }

    // Courses
    &[data-tag="apcs"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/home/icon/apcs--text.png");
      }
    }
    &[data-tag="pycs"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/home/icon/python--icon.svg");
      }
    }
    &[data-tag="ai"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/home/icon/ai--icon.png");
      }
    }
    &[data-tag="unity"] {
      padding-left: 2rem;
      &::before {
        background-image: url("../img/home/icon/unity--icon.svg");
      }
    }
  }
  &--right {
    margin: 0 0.5rem 0 0;
  }
  &[data-tag="homework"],
  &[data-tag="線上"],
  &[data-color="false"] {
    background: $color--secondary;
    color: $color__font--light;
  }
  &[data-tag="project"],
  &[data-tag="實體"],
  &[data-color="true"] {
    background: $color--accent;
    color: $color__font--light;
  }
  &[data-tag="數位"] {
    background: $color--primary;
    color: $color__font--light;
  }
  &--link {
    display: flex;

    @include cursor(pointer);

    transition: $transition;
    @include interaction(link);
    &:hover,
    &:focus,
    &:active {
      background: $color__opacity--accent;
    }
  }
  &--primary {
    background: $color--primary;

    color: $color__font--light;
  }
  &--outline {
    margin: 0;
    border: $border__input;
    background: transparent;

    @include text(normal, 400);
    color: $color__font--subtext;

    display: inline-block;
  }
}

.e-hr {
  width: 100%;
  height: $height__line;
  margin: 1.5rem 0;

  background: $color--gray3;
  border-radius: $radius--infinity;
}

.c-form {
  &__input {
    @include clear(input);
    height: $height__input;
    padding: $padding__btn;

    border-radius: $radius__input;
    border: $border__input;

    @include text(normal, 400);
    color: $color__font--text;

    @include interaction(input);

    &::placeholder {
      color: $color--gray4;
    }
  }
  &__select {
    @include cursor(pointer);
    &-wrapper {
      width: max-content;

      position: relative;
      &::after {
        @include pesudo("\f078");

        font-size: 0.875rem;
        color: $color__font--muted;

        left: auto;
        right: 0.5rem;
      }
    }
    &-input {
      @extend .c-form__input;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.c-dropdown {
  height: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  &__toggle {
    height: 100%;
    padding: 0.5rem 1rem;

    @include clear(btn);

    @include cursor(pointer);
    @include interaction(underline);
    @include heading(h6);
    color: $color--primary;

    position: relative;
    &::before {
      width: 1.5rem;
      background: $color--secondary;
      bottom: 0;

      transition: $transition;
    }
    &::after {
      display: none;
    }
    &:hover {
      &::before {
        width: calc(80% - 2rem);
      }
    }
    &.active {
      &::before {
        @include pesudo("");
        width: calc(80% - 2rem);
        height: $height__line;
        border-radius: 1rem;

        top: auto;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        opacity: 1;
      }
    }
    @media screen and (max-width: $sm) {
      padding: 0.5rem 1rem;
    }
  }
  &__item {
    border-radius: 0.25rem;

    @include text(small, "");
    color: $color__font--subtext;
    text-align: center;
    @include cursor(pointer);

    transition: $transition;
    &:hover {
      color: $color--primary;
      background: $color--gray6;
    }
  }
  &__menu {
    min-width: 10rem;
    padding: 0.5rem;

    background: transparent;
    border: none;

    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    transform: translate3d(-50%, $height__nav, 0) !important;

    display: block;

    transition: $transition;
    visibility: hidden;
    opacity: 0;
    &::after {
      @include pesudo("");
      width: 100%;
      height: 100%;

      background: $color--light;
      box-shadow: $shadow__dropdown;
      border-radius: $radius__dropdown;

      z-index: -1;

      transition: $transition;
      opacity: 0;
      transform: translateY(-1rem);
    }
    &.show {
      margin-top: -1rem !important;

      position: absolute !important;
      top: 0 !important;
      left: 50% !important;
      transform: translate3d(-50%, $height__nav, 0) !important;

      visibility: visible;
      opacity: 1;
      &::after {
        opacity: 1;
        transform: none;
      }
    }
  }
  &__link {
    padding: 0.25rem 1rem;

    @include text(normal, "");
    color: $color--primary;
    text-align: center;
    white-space: nowrap;

    @include interaction(link);

    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      .l-header__icon {
        color: $color--hover;
      }
    }
  }
}

.c-title {
  $_: &;
  width: max-content;
  padding: 0 0 1rem 0;
  margin-bottom: 0.5rem;

  position: relative;
  &__text {
    color: $color--primary;
  }
  &::before {
    @include pesudo("");
    width: 1.5rem;
    height: $height__line;

    background: $color--secondary;
    border-radius: $radius--infinity;

    top: auto;
    bottom: 0.325rem;
  }
  &--center {
    width: 100%;
    display: flex;
    justify-content: center;
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &--start {
    display: flex;
    justify-content: start;
    &::before {
      left: 0;
      transform: none;
    }
    @media screen and (max-width: $lg) {
      width: 100%;

      text-align: center;

      justify-content: center;
      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &--sub {
    margin-bottom: 0;
    &::before {
      bottom: 0.5rem;
    }
    #{$_}__text {
      @include text(medium, 500);
      @include cursor(pointer);
    }
    @media screen and (max-width: $lg) {
      width: max-content;

      text-align: start;

      justify-content: start;
      &::before {
        left: 0;
        transform: none;
      }
    }
  }
  &--activity {
    margin-bottom: 2rem;
    #{$_}__text {
      font-size: 32px;
    }
  }
}

.c-list {
  $_: &;
  @include text(normal, "");
  color: $color__font--subtext;
  &__title {
    margin-right: 0.5rem;

    @include text(normal, 500);
    color: $color__font--text;
    &--tag {
      margin: 0 0 0.5rem 0;
      display: flex;
      align-items: center;
    }
  }
  &__icon {
    margin-right: 0.5rem;

    font-size: 1rem;
    color: $color--primary;
    &--highlight {
      color: $color--secondary;
    }
    &--muted {
      color: $color--gray4;
    }
  }
  &__subtitle {
    margin-bottom: 0.5rem;

    @include text(normal, 500);
    color: $color--primary;
  }
  &__seg {
    margin-right: 0.5rem;
    @include text(normal, "");
    white-space: nowrap;
    &--stress {
      color: $color--primary;
    }
  }
  &--center {
    text-align: center;
  }
  &--tag {
    #{$_}__item {
      margin-bottom: 0.5rem !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &--block {
    #{$_}__item {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &--item {
    #{$_}__item {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &--dot {
    #{$_}__item {
      margin-left: 1.5rem;
      margin-bottom: 0.5rem;
      position: relative;
      &::before {
        @include pesudo("");
        width: 0.5rem;
        height: $height__line;

        background: $color--gray4;
        border-radius: $radius--infinity;

        top: 1rem;
        left: -1.25rem;
        transform: translateY(-50%);
      }
    }
  }
  &--start {
    #{$_}__item {
      &::before {
        top: 1rem;
      }
    }
  }
}

.c-accordion {
  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  &__item {
    width: 100%;
    border: none;
    background: transparent;
  }
  &__toggle {
    width: 100%;
    padding: $padding__accordion $padding__accordion $padding__accordion 3.5rem;

    @include clear(btn);

    background: $color--light;
    border-radius: $radius__accordion !important;
    box-shadow: $shadow__accordion;

    transition: $transition;

    position: relative;

    @include cursor(pointer);
    &:hover {
      background: $color--gray6;
    }
    &:focus,
    &:active {
      color: $color__font--text;
      background: $color__opacity--accent;
      box-shadow: $shadow__accordion;
      border: none;
    }
    &::after {
      @include pesudo("\f105");

      background-image: none;

      color: $color--primary;

      display: flex;
      justify-content: center;
      align-items: center;

      top: 50%;
      left: $margin__accordion-arrow;
      transform: translateY(-50%);
    }
    &:not(.collapsed) {
      color: $color__font--text;
      background: $color__opacity--accent;
      box-shadow: $shadow__accordion;
      border: none;
      border-radius: 0.5rem 0.5rem 0 0 !important;
      &::after {
        background-image: none;
        transform: translateY(-50%) rotate(90deg);
      }
    }
    @media screen and (max-width: $sm) {
      padding: 1.5rem 1.5rem 1.5rem 3rem;
      &::after {
        left: $margin__accordion-arrow--mobile;
      }
    }
  }
  &__title {
    @include text(normal, 500);
  }
  &__body {
    padding: 1.5rem;

    background: $color--light;
    box-shadow: $shadow__accordion;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  &__leading {
    min-width: 3.5rem;
  }
  &__subtitle {
    margin-bottom: 1rem;

    color: $color--primary;
  }
}

.c-card {
  $_: &;
  background: $color--light;
  box-shadow: $shadow__card;
  border-radius: $radius__card;

  overflow: hidden;

  transition: $transition;

  &:hover {
    box-shadow: $shadow__card--active;
  }
  &--link {
    @include cursor(pointer);
    #{$_}__img {
      width: 100%;
      height: 10rem;

      position: relative;
    }
    #{$_}__content {
      padding: 1rem;
    }
    #{$_}__title {
      padding: 0.25rem 1rem;

      background: $color--primary;
      border-radius: $radius__card-tag;
      box-shadow: $shadow__card-tag;

      @include text(normal, bold);
      color: $color__font--light;
      text-align: start;

      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
    }
    #{$_}__text {
      margin-bottom: 0;
      @include text(normal, "");
      color: $color__font--subtext;
      text-align: start;

      @include text-truncate(2);
      @include cursor(pointer);
    }
  }
  &--more {
    &:hover {
      box-shadow: $shadow__card--active;
    }
  }
  &--course {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #{$_}__content {
      padding: 1rem;

      @include cursor(pointer);
    }
    #{$_}__img {
      width: 6rem;
      aspect-ratio: 4 / 3;
    }
    #{$_}__title {
      @include text(medium, 500);
      color: $color--primary;
    }
    #{$_}__text {
      @include text(normal, "");
      color: $color__font--subtext;
    }
    #{$_}__btn {
      @include clear(btn);
      width: 100%;
      height: 2.75rem;
      padding: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include cursor(pointer);
    }
    #{$_}__action {
      @include square(100%, 0);

      background: $color--accent;
      @include text(normal, "");
      letter-spacing: 1rem;

      color: $color__font--light;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    #{$_}__icon {
      @include square(2.75rem, 0);

      background: $color--secondary;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.c-btns {
  width: 100%;
  padding: 0 1rem $padding__overview-btns;

  background: $color--light;
  border-radius: 0 0 0.5rem 0.5rem;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  &__btn {
    width: 100%;
    height: $height__overview-btn;
    padding: 0.5rem;

    background: $color--light;
    border: 1px solid $color--gray5;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: $transition;

    &:hover {
      background: $color--gray6;
    }
  }
  &__title {
    @include text(small, "");
    color: $color__font--subtext;
  }
  &__icon {
    width: 2rem;
    height: 2rem;
  }
}

.c-quote {
  width: 100%;
  height: max-content;
  padding: 2rem;
  margin-top: 2.5rem;

  background: $color--light;
  border-radius: $radius__card;

  position: relative;
  &::before,
  &::after {
    @include pesudo("");
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);

    background: linear-gradient(
      45deg,
      $color--primary,
      $color--secondary,
      $color--accent,
      $color--primary,
      $color--secondary,
      $color--accent,
      $color--primary,
      $color--secondary,
      $color--accent,
      $color--primary,
      $color--secondary,
      $color--accent
    );
    background-repeat: no-repeat;
    background-size: 300%;
    border-radius: 0.75rem;

    animation: steam 10s linear infinite;

    top: -0.25rem;
    left: -0.25rem;
    z-index: -1;
  }
  &::after {
    filter: blur(0.5rem);
  }
  &__text {
    @include text(medium, "");
    color: $color__font--subtext;
    text-align: start;
    @media screen and (max-width: $sm) {
      @include text(normal, "");
      text-align: center;
    }
  }
  &__em {
    margin-left: 0.5rem;

    @include text(large, 500);

    background: $color--primary;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    position: relative;

    display: inline;
    @media screen and (max-width: $sm) {
      margin-left: 0;

      @include text(medium, 500);

      display: block;
    }
    &::before {
      width: 100%;
      @include pesudo(attr(data-text));
      @include text(large, 500);

      background: $color__gradient--shine;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200%;

      mix-blend-mode: screen;
      animation: shine 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;

      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__author {
    width: max-content;
    margin-left: auto;

    @include text(normal, "");
    color: $color__font--muted;

    position: relative;
    &::before {
      @include pesudo("");
      width: 6rem;
      height: $height__line;

      background: $color--gray6;

      top: 50%;
      left: auto;
      right: calc(100% + 1rem);
      transform: translateY(-50%);
    }
    @media screen and (max-width: $sm) {
      margin-top: 1rem;
    }
  }
  &__deco {
    width: 3.5rem;
    height: 3.5rem;

    background: url("/img/home/icon/quote.svg");
    background-size: contain;

    position: absolute;
    top: -1.75rem;
    left: -1.75rem;

    animation: jello-horizontal 2s infinite both;
    @media screen and (max-width: $sm) {
      width: 3rem;
      height: 3rem;

      top: -1rem;
      left: -1rem;
    }
  }
}

.c-list-card {
  padding: $padding__list-card;
  margin-bottom: 1rem;

  border: $border__list-card;
  border-radius: $radius__block;
  &__img {
    width: 100%;
    aspect-ratio: 1 / 1;
  }
  &__heading {
    margin-bottom: 0.5rem;

    display: flex;
    align-items: center;
  }
  &__text {
    margin-right: 1rem;

    @include text(small, 400);
    color: $color__font--muted;

    display: flex;
    align-items: center;
  }
  &__title {
    @include text(medium, 500);
    color: $color__font--text;
  }
  &__subtitle {
    @include text(normal, 400);
    color: $color__font--subtext;
  }
  &__action {
    margin: 0 0 0 auto;
    padding-right: 2.5rem;

    position: relative;
    &::after {
      @include pesudo("\f054");
      color: $color__font--light;

      left: auto;
      right: 1rem;

      transition: $transition;
    }
    &:hover {
      &::after {
        right: 0.75rem;
      }
    }
    @media screen and (max-width: $sm) {
      margin: 0 auto;
    }
  }
}

.c-article {
  &__paragraph {
    @include text(normal, "");
    color: $color__font--subtext;
    white-space: pre-line;
  }
  &__subtitle {
    margin-bottom: 1rem;
    @include text(medium, "");
    color: $color__font--text;
  }
  &__title {
    padding: $padding__btn;
    margin-bottom: 1rem;

    @include text(large, "");
    color: $color__font--light;
    background: $color--accent;
    border-radius: $radius__btn;

    display: inline-block;
  }
  &__block {
    margin-bottom: $margin__block;
  }
  &__img {
    border-radius: $radius__block;
    overflow: hidden;
  }
}

.c-swiper {
  $width__action: 3rem;
  position: relative;
  &__action {
    @include clear(btn);
    @include square($width__action, 50%);
    min-width: $width__action;

    background: $color--light;
    box-shadow: $shadow__card-tag;

    transition: $transition;
    z-index: 1050;
    &::after {
      color: $color--primary;
    }
    &.next {
      right: calc(-1 * $width__action - 0.5rem);
      &::after {
        @include pesudo("\f054");
      }
    }
    &.prev {
      left: calc(-1 * $width__action - 0.5rem);
      &::after {
        @include pesudo("\f053");
      }
    }
    &:hover,
    &:focus,
    &:active {
      background: $color--accent;
      box-shadow: $shadow__card--active;
      &::after {
        color: $color__font--light;
      }
    }
  }
  &__pagination {
    position: absolute;
    z-index: 1050;
  }
  .swiper-pagination {
    bottom: -1rem;
  }
}

.c-table {
  &__row {
    display: grid;
  }
  &__col {
    padding: 0.5rem;
  }
}
