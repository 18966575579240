.c-map {
  $_: &;
  padding: 2rem;

  // box-shadow: $shadow__card;
  border-radius: $radius__card;
  background: $color__opacity--accent;

  z-index: -1000;
  @media screen and (max-width: $lg) {
    padding: 1rem;
  }
  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__img {
    height: 3rem;
    margin-bottom: 0.5rem;
  }
  &__branch {
    @include text(normal, 500);
    color: $color__font--subtext;
    text-align: center;
  }
  &__subtext {
    @include text(small, "");
    color: $color__font--muted;
    text-align: center;
  }
  &__card {
    width: 100%;
    height: 100%;
    padding: 1rem;

    @include clear(btn);

    background: $color--light;
    box-shadow: $shadow__card;
    border-radius: $radius__card;

    transition: $transition;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include cursor(pointer);
    &::before {
      @include pesudo("");
      width: 100%;
      height: 100%;

      background: $color--light;
      border-radius: 0.5rem 0.5rem 0 0;

      transition: $transition;
      transform-origin: 50% 50%;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    &.active {
      position: relative;
      border-radius: 0.5rem 0.5rem 0 0;

      z-index: 1002;
      #{$_}__title {
        color: $color--primary;
      }
      #{$_}__deco {
        opacity: 1;
      }
    }
    &:disabled {
      @include cursor(default);
    }
  }
  &__deco {
    width: calc(100% + 0.4rem);
    height: calc(100% + $height__overview-btn + $padding__overview-btns + 0.4rem);
    @media screen and (max-width: $lg) {
      height: calc(
        100% + $height__overview-btn * $num__btn + $padding__overview-btns + $margin__overview-btn * ($num__btn - 1) +
          0.4rem
      );
    }
    border-radius: 0.75rem;

    box-shadow: $shadow__card--active;

    position: absolute;
    top: -0.2rem;
    left: -0.2rem;

    z-index: -2;

    transition: $transition;
    opacity: 0;

    overflow: hidden;
    &::after {
      @include pesudo("");
      width: 250%;
      height: 500%;

      background: $color__gradient--flow;
      background-size: 100% 100%;

      -webkit-animation: gradient-flow 4s ease infinite;
      -moz-animation: gradient-flow 4s ease infinite;
      animation: gradient-flow 4s ease infinite;

      transform-origin: center center;
      left: -75%;
      top: -200%;
    }
  }
  &__icon {
    @extend .e-tag, .e-tag--icon;
    width: max-content;
    padding: 0.5rem 0.5rem 0.5rem 2.75rem !important;
    &::before {
      width: 1.75rem;
      height: 1.75rem;
      left: 0.5rem;
    }
  }
  &__title {
    @include text(medium, 500);
    color: $color__font--text;
    text-align: center;

    transition: $transition;
  }
  &__hr {
    margin: 0.5rem 0;
  }
  &__divider {
    margin: 0;

    background: $color--gray3;
  }
  &__content {
    @include text(normal, "");
    color: $color__font--muted;
    text-align: center;
  }
  &__badge {
    padding: 0.25rem 0.5rem;

    background: $color--secondary;
    box-shadow: $shadow__tab;
    border-radius: $radius__tag;

    @include text(small, "");
    color: $color__font--light;

    position: absolute;
    top: 0.5rem;
    right: -1rem;
    &::after {
      @include pesudo("");
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1rem 1rem 0 0;
      border-color: rgb(161, 125, 179) transparent transparent transparent;

      top: calc(100% - 0.125rem);
      left: auto;
      right: 0;
      z-index: -1;
    }
  }
}

.l-overview {
  &__bg {
    width: 100%;
    height: 100vh;

    background: $color__opacity--light;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}
