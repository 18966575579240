$color-winter--primary: #0b3144;
$color-winter--secondary: #d5ebf7;
$color-winter--schedule-1: #73bce5;
$color-winter--schedule-2: #3683ad;
$font-family__key-visual: GenSenRounded, "Inter", "Noto Sans TC", sans-serif;
$letter-spacing: 0.25rem;
$spec__mobile: $lg;
$weight__stress: 500;

.l-winter-camp-2025 {
  $_: &;
  &__heading {
    margin-bottom: 5rem;
    color: $color-winter--primary;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title {
      position: relative;
      &__text {
        @include heading(h4);
      }
      &__deco {
        width: 120%;
        height: 1.25rem;

        background-image: url("../img/winter-camp-2025/title-deco.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }
    }
    &__subtitle {
      @include text(medium, 500);
    }
    @media screen and (max-width: $spec__mobile) {
      margin-bottom: 2rem;
    }
  }
  &__key-visual {
    height: 45vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #73bde5;

    position: relative;
    overflow: hidden;
    z-index: 0;
    @media screen and (max-width: $spec__mobile) {
      height: 50vh;
    }
    &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__title {
      font-family: $font-family__key-visual;
      font-weight: 700;
      font-size: 3.75rem;
      letter-spacing: $letter-spacing;
      color: $color-winter--primary;

      position: relative;
      z-index: 1;
      @media screen and (max-width: $lg) {
        font-size: 2.5rem;
      }
      &-deco {
        font-size: 100px;
        font-weight: 700;
        letter-spacing: $letter-spacing;
        color: rgba($color: #ffffff, $alpha: 0.3);

        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%);
        z-index: -1;
        @media screen and (max-width: $lg) {
          font-size: 4rem;
        }
      }
    }
    &__subtitle {
      font-family: $font-family__key-visual;
      font-weight: 600;
      font-size: 1.75rem;
      letter-spacing: $letter-spacing;
      color: $color-winter--primary;
      text-align: center;
      @media screen and (max-width: $spec__mobile) {
        font-size: 1.5rem;
      }
    }
    &__text {
      height: 48px;

      font-family: $font-family__key-visual;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0.25rem;
      color: $color-winter--primary;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      z-index: 0;
      @media screen and (max-width: $lg) {
        font-size: 1rem;
      }
      &-deco {
        width: 150%;
        height: 60px;

        background-image: url("../img/winter-camp-2025/key-visual/text-deco.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        position: absolute;
        left: 50%;
        top: -5px;
        transform: translate(-50%);
        z-index: -1;
        @media screen and (max-width: $lg) {
          width: 120%;
        }
      }
    }
    &__bg {
      width: 100%;
      height: 40%;

      background-image: url("../img/winter-camp-2025/key-visual/bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    &__fg {
      width: 20%;
      height: 100%;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;

      position: absolute;
      top: 0;
      z-index: 1;
      &--left {
        background-image: url("../img/winter-camp-2025/key-visual/fg--left.png");
        left: 0;
      }
      &--right {
        background-image: url("../img/winter-camp-2025/key-visual/fg--right.png");
        right: 0;
      }
    }
    // &__deco {
    //   width: 100%;
    //   height: 40px;

    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: top;

    //   position: absolute;
    //   left: 0;
    //   z-index: 1;
    //   &--top {
    //     background-image: url("../img/winter-camp-2025/key-visual/deco--top.png");
    //     top: -1px;
    //   }
    //   &--bottom {
    //     background-image: url("../img/winter-camp-2025/key-visual/deco--bottom.png");
    //     bottom: -1px;
    //   }
    // }
    &__icon {
      @include square(3rem, 50%);
      padding: 0.5rem;
      background-color: #ffffff;
      box-shadow: $shadow__course-nav--active;
    }
  }
  &__feature {
    &__row {
      margin-bottom: 10rem;
      @media screen and (max-width: $sm) {
        margin-top: 6rem;
      }
      @media screen and (max-width: $spec__mobile) {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &__block {
      height: 240px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      @include text(normal, 400);
      color: $color-winter--primary;

      position: relative;
      @each $i in (1, 2, 3, 4, 5, 6, 7, 8) {
        &[data-number="#{$i}"] {
          @if ($i % 2 != 0) {
            padding-right: 2rem;
          } @else {
            padding-left: 2rem;
          }
        }
      }
      @media screen and (max-width: $spec__mobile) {
        padding: 0 !important;
      }
    }
    &__title {
      @include heading(h5);
      @media screen and (max-width: $spec__mobile) {
        text-align: center;
      }
    }
    &__content {
      @include text(normal, 400);
      @media screen and (max-width: $spec__mobile) {
        text-align: center;
      }
    }
    &__bg {
      width: 140%;
      height: 130%;

      background-color: $color-winter--secondary;
      position: absolute;
      top: -15%;
      z-index: -1;
      @each $i in (1, 2, 3, 4, 5) {
        &[data-number="#{$i}"] {
          @if ($i % 2 != 0) {
            right: 0;
          } @else {
            left: 0;
          }
        }
      }
    }
    &__number {
      color: #3683ad80;
      font-size: 128px;
      font-weight: 900;

      position: absolute;
      top: -15%;
      z-index: -1;
      @each $i in (1, 2, 3, 4, 5) {
        &[data-number="#{$i}"] {
          @if ($i % 2 != 0) {
            right: 0;
          } @else {
            left: 0;
          }
        }
      }
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      z-index: 1;
      // &--1 {
      //   height: 3.75rem;

      //   background-image: url("../img/winter-camp-2025/feature/deco-1.png");
      //   background-position: right;

      //   top: -1.5rem;
      //   right: 1.5rem;
      // }
      &--2 {
        height: 7.5rem;

        background-image: url("../img/winter-camp-2025/feature/deco-2.png");
        background-position: left;

        bottom: -3.75rem;
        left: -3.75rem;
      }
      // &--3 {
      //   height: 6.25rem;

      //   background-image: url("../img/winter-camp-2025/feature/deco-3.png");
      //   background-position: center;

      //   bottom: -3.75rem;
      //   left: 0;
      // }
      &--4 {
        height: 17.5rem;

        background-image: url("../img/winter-camp-2025/feature/deco-2.png");
        background-position: center;
        opacity: 0.2;

        top: -80%;
        left: 40%;
      }
      // &--5 {
      //   height: 3.75rem;

      //   background-image: url("../img/winter-camp-2025/feature/deco-5.png");
      //   background-position: left;

      //   top: -1.5rem;
      //   right: 0.5rem;
      // }
      &--6 {
        height: 3.75rem;

        background-image: url("../img/winter-camp-2025/feature/deco-5.png");
        background-position: left;

        top: -1.5rem;
        right: 0.5rem;
      }
      &--7 {
        height: 17.5rem;

        background-image: url("../img/winter-camp-2025/feature/deco-2.png");
        background-position: left;
        opacity: 0.2;

        top: 50%;
        right: 110%;
      }
    }
  }
  &__course {
    &__block {
      width: 9rem;
      aspect-ratio: 1 / 1;

      background-color: #73bce54d;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      width: 3.75rem;
      height: auto;
    }
    &__title {
      @include text(large, 400);
      color: $color-winter--primary;
    }
    &__content {
      @include text(normal, 400);
      color: $color-winter--primary;
      text-align: center;
    }
    &__action {
      border-radius: 1000rem;
      background-color: $color-winter--schedule-1;
    }
  }
  @mixin gird-name($name) {
    &[data-grid="#{$name}"] {
      grid-area: #{$name};
    }
  }
  &__schedule {
    &__table {
      width: 100%;
      @media screen and (min-width: $spec__mobile) and (max-width: $xl) {
        min-width: max-content;
      }
    }
    &__row {
      width: 100%;
      min-width: max-content;
      padding: 1rem 0;
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1fr 1.5fr 1fr 1fr;
      grid-template-areas:
        "title date location price tutor action"
        "subtitle weekday location earlyPrice tutor action"
        "subtitle time location groupPrice tutor action"
        "subtitle totalTime location groupPrice tutor action";

      border-radius: $radius__block;
      &--head {
        height: 4.5rem;
        padding: 0;

        border-radius: $radius__block;

        grid-template-areas: "course time location price tutor action";
        @media screen and (max-width: $spec__mobile) {
          display: none;
        }
        #{$_}__schedule__col {
          font-weight: $weight__stress;
          color: #ffffff;
          justify-content: center;
        }
      }
      &--top,
      &--bottom {
        background-color: $color-winter--schedule-1;
      }
      &--center {
        background-color: $color-winter--schedule-2;
      }
      &[data-row="odd"] {
        background: $color-winter--secondary;
      }
      @media screen and (max-width: $spec__mobile) {
        margin-bottom: 1rem;
        padding: 1rem;
        grid-template-columns: auto auto 1fr;
        grid-template-rows: 1.5fr 1fr 1fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr;
        grid-template-areas:
          "title title title"
          "date date date"
          "weekday time time"
          "totalTime totalTime totalTime"
          "location location location"
          "tutor tutor tutor"
          "price price price"
          "earlyPrice earlyPrice earlyPrice"
          "groupPrice groupPrice groupPrice"
          "action action action";
        &[data-row="even"] {
          background: $color-winter--secondary;
        }
      }
    }
    &__col {
      @include text(normal, 400);
      white-space: pre-line;
      text-align: center;
      color: $color-winter--primary;
      text-wrap: wrap;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @each $i in ("course", "time", "location", "price", "tutor", "action") {
        @include gird-name($i);
      }
      @each $i
        in (
          "title",
          "subtitle",
          "date",
          "weekday",
          "time",
          "totalTime",
          "location",
          "price",
          "earlyPrice",
          "groupPrice",
          "tutor",
          "action"
        )
      {
        @include gird-name($i);
      }
      @media screen and (max-width: $spec__mobile) {
        text-align: start;
        align-items: flex-start;
        justify-content: flex-start;
        &[data-grid="action"] {
          align-items: flex-end;
          justify-content: flex-end;
        }
        &[data-grid="title"],
        &[data-grid="subtitle"] {
          @include text(medium, 500);
        }
        &[data-grid="date"],
        &[data-grid="weekday"] {
          margin-right: 1rem;
          width: max-content;
        }
        &[data-grid="time"],
        &[data-grid="totalTime"] {
          width: 100%;
        }
      }
    }
    &__action {
      border-radius: 1000rem;
      &--top,
      &--bottom {
        background-color: $color-winter--schedule-1;
      }
      &--center {
        background-color: $color-winter--schedule-2;
      }
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      z-index: 1;
      @media screen and (max-width: $spec__mobile) {
        display: none;
      }
      &--top {
        height: 6.25rem;

        background-image: url("../img/winter-camp-2025/feature/deco-3.png");
        background-position: right;

        top: 1rem;
        right: 0;
        transform: translateY(-50%);
      }
      &--bottom {
        height: 3.75rem;

        background-image: url("../img/winter-camp-2025/feature/deco-1.png");
        background-position: left;

        top: 0;
        left: 10%;
        transform: translateY(-50%);
      }
    }
  }
  &__qa {
    position: relative;
    &__item {
      @include clear(btn);

      border: none;
      background: transparent;

      transition: $transition;
    }
    &__toggle {
      @include clear(btn);

      padding: $padding__block;
      background: $color--light;
      border-radius: $radius__block !important;
      box-shadow: map-get($map: map-get($shadow, summer), $key: item);

      @include text(normal, 500);
      color: $color-winter--primary;

      display: flex;
      align-items: center;
      &:hover,
      &:focus,
      &:active {
        background: $color-winter--secondary;
        box-shadow: map-get($map: map-get($shadow, summer), $key: item);
      }
      &:not(.collapsed) {
        background: $color-winter--secondary;
        box-shadow: map-get($map: map-get($shadow, summer), $key: item);
        border-radius: $radius__block !important;

        color: $color-winter--primary;
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
      }
    }
    &__title {
      width: max-content;
      padding: 0.5rem 1rem;

      background: $color-winter--secondary;
      border-radius: $radius--infinity;

      @include text(normal, 500);
      color: $color-winter--primary;
    }
    &__text {
      @include text(normal, 400);
      color: $color-winter--primary;
    }
    &__content {
      padding: $padding__accordion;

      border-radius: 0 0 $radius__block $radius__block;
      background: color(light);
      box-shadow: map-get($map: map-get($shadow, summer), $key: block);
    }
    &__wrapper {
      padding-top: 0;
    }
    &__deco {
      width: 100%;
      height: 7.5rem;

      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../img/winter-camp-2025/feature/deco-2.png");
      background-position: right;

      position: absolute;
      z-index: -1;
      top: 0;
      right: -2rem;
    }
  }
  &__carousel {
    padding-bottom: 15rem;
    &__swiper {
      height: 500px;
    }
    &__item {
      border-radius: $radius__block;
      transition: 0.3s;
      overflow: hidden;
      img {
        transition: none;
      }
      &.active {
        overflow: visible;
        z-index: 1;
        img {
          width: 200% !important;
          border-radius: $radius__block;
          transform: translateX(-25%);
        }
      }
      &:not(.active) {
        height: 50%;
        transform: translateY(50%);
      }
    }
    &__action {
      @include clear(btn);
      @include square(2rem, 50%);
      background: #dff1f280;
      border: $height__line solid color(light);

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 50%;
      z-index: 2;
      transition: 0.3s;
      &.prev {
        left: 15%;
      }
      &.next {
        right: 15%;
      }
      &:hover,
      &:focus,
      &:active {
        background: #dff1f280;
        border: $height__line solid color(light);
      }
      &:disabled {
        opacity: 0.5;
      }
      @media screen and(max-width:$sm) {
        display: none;
      }
    }
    &__bg {
      width: 100%;
      height: 30%;

      background-image: url("../img/winter-camp-2025/carousel/bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    &__fg {
      width: 100%;
      height: 3.75rem;

      background-image: url("../img/winter-camp-2025/key-visual/deco--bottom.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    &__deco {
      width: 100%;

      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      z-index: -1;
      &--1 {
        height: 7.5rem;

        background-image: url("../img/winter-camp-2025/feature/deco-2.png");
        background-position: right;

        top: 10%;
        right: 0;
      }
      &--2 {
        height: 17.5rem;

        background-image: url("../img/winter-camp-2025/feature/deco-2.png");
        background-position: left;
        opacity: 0.2;

        top: 0;
        left: 0;
      }
    }
  }
}
