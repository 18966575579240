.l-learning-path {
  $_: &;
  $width__glow: 0.5rem;
  &__deco {
    @include square(100%, $radius__card);

    background: $color__bg--accent;
    border-radius: calc($radius__card * 1.5);

    position: absolute;
    top: 0;
    left: 0;

    z-index: -2;

    transition: $transition;
  }
  &__list-card {
    @include clear(btn);
    width: 100%;
    padding: $padding__card;
    margin-bottom: $margin__block;

    flex-direction: column;
    align-items: flex-start;
    @include cursor(pointer);
    transition: $transition;

    position: relative;
    z-index: 0;
    &:hover,
    &:focus,
    &:active {
      background: $color__bg--accent;
      #{$_}__deco {
        @include square(calc(100% + $width__glow), calc($radius__card * 1.5));
        background: $color__bg--accent;

        top: calc(-1 * $width__glow / 2);
        left: calc(-1 * $width__glow / 2);
      }
    }
    &.active {
      &::before {
        @include pesudo("");
        @include square(100%, $radius__card);
        background: $color--light;
        z-index: -1;
      }
      #{$_}__deco {
        @include square(calc(100% + $width__glow), calc($radius__card * 1.5));

        box-shadow: $shadow__card--active;

        top: calc(-1 * $width__glow / 2);
        left: calc(-1 * $width__glow / 2);

        overflow: hidden;
        &::after {
          @include pesudo("");
          width: 300%;
          height: 300%;

          background: $color__gradient--flow;
          background-size: 100% 100%;
          background-position: top;

          -webkit-animation: gradient-flow 4s ease infinite;
          -moz-animation: gradient-flow 4s ease infinite;
          animation: gradient-flow 4s ease infinite;

          top: -100%;
          left: -100%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &__item {
    border: $border__list-card;
  }
  &__title {
    @include text(medium, 500);
    color: $color--primary;
    text-align: start;
  }
  &__subtitle {
    margin-bottom: 0;

    @include text(normal, 400);
    text-align: start;
    color: $color__font--subtext;
  }
  &__content {
    margin-top: 1rem;
  }
  &__action {
    margin-top: 1rem;
  }
  &__img {
    width: 100%;
    aspect-ratio: 3880 / 2389;
    margin-top: $margin__block;

    background-image: url("../img/learning-path/path--horizontal.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    @media screen and (max-width: $sm) {
      aspect-ratio: 3046 / 2770;
      background-image: url("../img/learning-path/path--vertical.jpg");
    }
  }
}
