/* google fonts */
// Noto Sans TC
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");
// Inter
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

@font-face {
  font-family: Krungthep;
  src: url("./fonts/Krungthep.ttf");
}

@font-face {
  font-family: "GenSenRounded";
  src: url("./fonts/GenSenRounded/GenSenRounded-EL.ttc");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "GenSenRounded";
  src: url("./fonts/GenSenRounded/GenSenRounded-L.ttc");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GenSenRounded";
  src: url("./fonts/GenSenRounded/GenSenRounded-R.ttc");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GenSenRounded";
  src: url("./fonts/GenSenRounded/GenSenRounded-M.ttc");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GenSenRounded";
  src: url("./fonts/GenSenRounded/GenSenRounded-B.ttc");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GenSenRounded";
  src: url("./fonts/GenSenRounded/GenSenRounded-H.ttc");
  font-weight: 900;
  font-style: normal;
}

@mixin heading($size) {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-style: normal;
  line-height: 110%;
  letter-spacing: 0.03125rem;

  cursor: default;

  @if $size == h1 {
    font-size: 3.25rem;
    font-weight: 500;
  } @else if $size == h2 {
    font-size: 2.5rem;
    font-weight: 500;
  } @else if $size == h3 {
    font-size: 2rem;
    font-weight: 500;
  } @else if $size == h4 {
    font-size: 1.5rem;
    font-weight: 500;
  } @else if $size == h5 {
    font-size: 1.25rem;
    font-weight: 500;
  } @else if $size == h6 {
    font-size: 1rem;
    font-weight: 500;
  }
}

@mixin text($size, $weight) {
  font-family: "Inter", "Noto Sans TC", sans-serif;
  font-style: normal;
  line-height: 200%;
  letter-spacing: 0.03125rem;

  cursor: default;

  @if $size == large {
    font-size: 1.25rem;
  } @else if $size == medium {
    font-size: 1.125rem;
  } @else if $size == normal {
    font-size: 1rem;
  } @else if $size == small {
    font-size: 0.875rem;
  } @else if $size == extra-small {
    font-size: 0.75rem;
  }

  @if $weight == bold {
    font-weight: 700;
  } @else if $weight == "" {
    font-weight: 400;
  } @else {
    font-weight: $weight;
  }
}

@mixin key-visual-font {
  font-family: Krungthep, "Inter", "Noto Sans TC", sans-serif;
  font-style: normal;
  line-height: 140%;
  letter-spacing: -0.1875rem;
  text-align: center;
  font-weight: normal;
  font-size: 4rem;
  @media screen and (max-width: $xl) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: $lg) {
    font-size: 3rem;
  }
}

@mixin interaction($element) {
  transition: $transition;

  @if $element == btn {
    &:hover {
      color: $color__font--light;
      background: $color--hover;
    }
    &:focus,
    &:active {
      color: $color__font--light;
      background: $color--active;
      box-shadow: none;
    }
  } @else if $element == btn--opacity {
    &:hover {
      background: $color__opacity--primary;
    }
    &:focus,
    &:active {
      .e-icon {
        color: $color__font--light;
      }
      background: $color--primary;
    }
  } @else if $element == link {
    &:hover {
      color: $color--hover;
    }
    &:focus,
    &:active {
      color: $color--active;
    }
  } @else if $element == link--light {
    &:hover {
      color: $color--hover;
    }
    &:focus,
    &:active {
      color: $color--active;
    }
  } @else if $element == tab {
    &:hover {
    }
    &:focus,
    &:active {
      color: $color--light;
      background: $color--primary;
    }
  } @else if $element == dropdown-item--light {
    &:hover {
      background: $color__opacity--primary;
      color: $color--hover;
    }
    &:focus,
    &:active {
      background: $color__opacity--primary;
      color: $color--active;
    }
  } @else if $element == dropdown-item--dark {
    &:hover {
      background: $color__opacity--hover;
      color: $color--light;
    }
    &:focus,
    &:active {
      background: $color__opacity--hover;
      color: $color--light;
    }
  } @else if $element == underline {
    &::before {
      @include pesudo("");
      height: $height__line;
      border-radius: 1rem;

      top: auto;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      opacity: 0;

      transition: $transition;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  } @else if $element == input {
    &:focus,
    &:active {
      border: $border__input;
    }
  }
}

@mixin clear($element) {
  @if $element == btn {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    &:hover,
    &:focus {
      background: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }
  } @else if $element == scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  } @else if $element == input {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    &:focus,
    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }

    // remove expand arrow
    -moz-appearance: none;
    -webkit-appearance: none;
    &::-ms-expand {
      display: none;
    }
  }
}

@mixin pesudo($content) {
  content: $content;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 1rem;

  position: absolute;

  @if $content != "" {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else {
    top: 0;
    left: 0;
  }
}

@mixin text-truncate($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $color--primary;
    border-radius: 10rem;
    background-clip: content-box;
    transition: $transition;
    &:hover {
      background: $color--hover;
    }
  }
}

@mixin scrollbar--gray {
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10rem;
  }

  &::-webkit-scrollbar {
    width: 0.125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $color--gray5;

    border-radius: 10rem;
    background-clip: content-box;
    transition: $transition;
    &:hover {
      background: $color--hover;
    }
  }
}

@mixin square($size, $radius) {
  width: $size;
  height: $size;

  border-radius: $radius;
}

@mixin cursor($cursor) {
  cursor: $cursor;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  a,
  span,
  p,
  div,
  ul,
  li,
  label,
  input,
  select,
  svg {
    cursor: $cursor;
  }
}

@mixin gradient-bg($style) {
  @if $style == banner {
    background: $color__bg--banner;
    background-size: 600% 600%;

    -webkit-animation: gradient-bg 20s ease infinite;
    -moz-animation: gradient-bg 20s ease infinite;
    animation: gradient-bg 20s ease infinite;
  } @else if $style == double {
    background: $color__gradient--double;
    background-repeat: no-repeat;
    background-size: 300%;

    color: $color__font--light;

    animation: steam 5s linear infinite;
  } @else if $style == summer {
    background: linear-gradient(
      270deg,
      map-get(map-get($color, summer), 2),
      map-get(map-get($color, summer), 1),
      map-get(map-get($color, summer), 3)
    );
    background-size: 600% 600%;

    -webkit-animation: gradient-bg 20s ease infinite;
    -moz-animation: gradient-bg 20s ease infinite;
    animation: gradient-bg 20s ease infinite;
  }
}

@function color($keys...) {
  $map: $color;
  @each $key in $keys {
    $map: map-get($map, $key);
    @if $map == null {
      @warn "Key `#{$key}` not found in `#{$keys}`.";
      @return null;
    }
  }
  @return $map;
}
