.l-competition-training {
  $_: &;
  $width__time: 3rem;
  $color__timeline: $color__line;
  @mixin right-item-style() {
    #{$_}__time {
      left: -1 * $width__time;
      right: auto;
      &::before {
        left: 100%;
        right: auto;
      }
      &::after {
        left: calc(100% + $width__deco-line);
        right: auto;
      }
    }
    @media screen and (max-width: $lg) {
      padding: 0 0 0 $width__time * 2;
      #{$_}__time {
        left: 0;
      }
    }
  }
  &__block {
    position: relative;
    &[data-dir="right"] {
      padding: 0 0 0 $width__time;
      @include right-item-style;
      #{$_}__time {
        background: $color--accent;

        &::before,
        &::after {
          background: $color--accent;
        }
      }
    }
    &[data-dir="left"] {
      padding: 0 $width__time 0 0;
      #{$_}__time {
        background: $color--secondary;

        left: auto;
        right: -1 * $width__time;
        &::before,
        &::after {
          background: $color--secondary;
        }
        &::before {
          left: auto;
          right: 100%;
        }
        &::after {
          left: auto;
          right: calc(100% + $width__deco-line);
        }
      }
      @media screen and (max-width: $lg) {
        @include right-item-style;
      }
    }
  }
  &__time {
    @include square($width__time, 50%);

    @include text(normal, 400);
    color: $color__font--light;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &::before,
    &::after {
      @include pesudo("");

      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      width: $width__deco-line;
      height: $height__line;
    }
    &::after {
      @include square($width__deco-dot, 50%);
    }
  }
  &__graph {
    padding: $margin__block 0 $margin__block * 2 0;
    position: relative;
  }
  &__timeline {
    width: $height__line;
    height: 100%;

    background: $color__timeline;

    position: absolute;
    top: 0;
    left: 50%;
    &::before,
    &::after {
      @include pesudo("");
      @include square($width__deco-dot, 50%);
      background: $color__timeline;

      left: 50%;
      transform: translateX(-50%);
    }
    &::before {
      top: 0;
    }
    &::after {
      top: auto;
      bottom: 0;
    }
    @media screen and (max-width: $lg) {
      left: calc($width__time / 2);
    }
  }
  &__item {
    margin-bottom: 0.5rem;

    border: none;
  }
  &__toggle {
    $width__border: $height__border * 2;
    @include clear(btn);
    min-height: 3.375rem;
    padding: 0.5rem 1rem;

    border: $border__item;
    border-radius: $radius__btn;

    @include text(normal, 500);
    color: $color__font--text;

    display: flex;
    align-items: center;
    justify-content: space-between;

    $transition: $transition;
    &::after {
      @include pesudo("");
      @include square(100%, calc($radius__btn - $width__border / 2));
      background: $color--light;

      display: block;
      transform: none;
      z-index: -1;

      $transition: $transition;
    }
    &:hover,
    &:focus,
    &:active {
      border: $border__item;

      &::after {
        background: $color--gray6;
      }
    }
    &:not(.collapsed) {
      box-shadow: $shadow__accordion;
      border: $border__hidden;
      border-radius: $radius__btn;

      color: $color__font--text;

      position: relative;
      z-index: 1;

      $transition: $transition;
      &::after {
        background: $color--light;

        transform: none;
      }
      &::before {
        @include pesudo("");
        @include square(calc(100% + $width__border), $radius__btn);
        @include gradient-bg(double);
        box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.1);

        top: calc(-1 * $width__border / 2);
        left: calc(-1 * $width__border / 2);
        z-index: -2;
      }
    }
  }
  &__body {
    padding: 0 0.5rem;
  }
  &__content {
    padding: $padding__block;
    border: $border__item;
    border-top: none;
    border-radius: 0 0 $radius__block $radius__block;

    @include text(normal, 400);
    color: $color__font--subtext;
  }
  &__text {
    color: $color--primary;
  }
  &__tag {
    margin: 0;
    @media screen and (max-width: $lg) {
      margin-left: 0.5rem;
    }
  }
  &__peroid {
    margin-bottom: $margin__section;
  }
}
