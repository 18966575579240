.lg {
  $_: &;
  &-backdrop {
    background: rgba(0, 0, 0, 0.3);
  }
  &-toolbar {
    background: $color--primary;
    #{$_}-icon,
    #{$_}-counter {
      color: #fff;
    }
  }
  &-img-wrap {
    padding: $padding__card;
  }
}

#tsparticles {
  height: 100%;
}
