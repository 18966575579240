$color--primary: var(--color--primary);
$color--secondary: var(--color--secondary);
$color--hover: var(--color--hover);
$color--active: var(--color--active);
$color--accent: var(--color--accent);
$color--error: var(--color--error);
$color--stress: var(--color--stress);

$color--light: var(--color--light);

$color--gray1: var(--color--gray1);
$color--gray2: var(--color--gray2);
$color--gray3: var(--color--gray3);
$color--gray4: var(--color--gray4);
$color--gray5: var(--color--gray5);
$color--gray6: var(--color--gray6);
$color--gray7: var(--color--gray7);

$color__font--text: var(--color__font--text);
$color__font--subtext: var(--color__font--subtext);
$color__font--light: var(--color__font--light);
$color__font--muted: var(--color__font--muted);

$color__opacity--primary: var(--color__opacity--primary);
$color__opacity--secondary: var(--color__opacity--secondary);
$color__opacity--accent: var(--color__opacity--accent);
$color__opacity--hover: var(--color__opacity--hover);
$color__opacity--dark: var(--color__opacity--dark);
$color__opacity--light: var(--color__opacity--light);
$color__opacity--gradient: var(--color__opacity--gradient);

$color__shadow: var(--color__shadow);
$color__shadow--active: var(--color__shadow--active);
$color__shadow--dropdown: var(--color__shadow--dropdown);

$color__bg--banner: var(--color__bg--banner);
$color__bg--accent: var(--color__bg--accent);

$color__link: var(--color__link);
$color__line: $color--gray5;

$color__gradient--light: var(--color__gradient--light);
$color__gradient--light-reverse: var(--color__gradient--light-reverse);
$color__gradient--main: var(--color__gradient--main);
$color__gradient--shine: var(--color__gradient--shine);
$color__gradient--shine-m: var(--color__gradient--shine-m);
$color__gradient--flow: var(--color__gradient--flow);
$color__gradient--double: var(--color__gradient--double);

$width__deco-line: 2rem;
$width__deco-dot: 0.5rem;

$height__line: 0.125rem;
$height__border: 0.0625rem;
$height__nav: 4rem;
$height__key-visual: 80vh;
$height__footer: 9.5rem;
$height__overview-btn: 5rem;
$height__btn: 2.75rem;
$height__input: 2.75rem;
$height__banner: 15rem;

$padding__page: 4rem 0;
$padding__overview-btns: 1rem;
$padding__card: 2rem;
$padding__card--mobile: 1rem;
$padding__swiper: 0.5rem;
$padding__btn: 0 1rem;
$padding__list-card: 1.5rem;
$padding__accordion: 1.5rem;
$padding__block: 1rem;

$margin__overview-btn: 0.5rem;
$margin__accordion-arrow: $padding__accordion;
$margin__accordion-arrow--mobile: calc($padding__accordion / 2);
$margin__block: 1.5rem;
$margin__item: 0.5rem;
$margin__section: 3rem;
$margin__section--mobile: 1rem;

$radius__tab: 0.5rem;
$radius__tab--header: 0.5rem 0.5rem 0 0;
$radius__input: 0.25rem;
$radius__btn: 0.25rem;
$radius__dropdown: 0 0 0.5rem 0.5rem;
$radius__heading: 0.5rem;
$radius__block: 0.5rem;
$radius--infinity: 100rem;
$radius__tag: 0.25rem;
$radius__accordion: 0.5rem;
$radius__card: 0.5rem;
$radius__card-tag: 0 0.25rem 0.25rem 0;

$border__tab: $height__border solid $color--gray5;
$border__input: $height__border solid $color--gray5;
$border__item: $height__border solid $color--gray5;
$border__hidden: $height__border solid transparent;
$border__list-card: $height__border solid $color--gray5;
$border__input--active: $height__border solid $color--primary;
$border__section: $height__border solid $color--primary;
$border__quote: 0.25rem solid $color--secondary;

$shadow__tab: 0 0 0.2rem 0 $color__shadow;
$shadow__nav: 0 0 0.3rem 0 $color__shadow;
$shadow__card-tag: 0 0 0.3rem 0 $color__shadow;
$shadow__dropdown: 0 0.1rem 0.05rem 0 $color__shadow;
$shadow__course-nav: 0 0 0.1rem 0 $color__shadow;
$shadow__course-nav--active: 0 0 0.5rem 0 $color__shadow;
$shadow__accordion: 0 0 0.0625rem 0 $color__shadow;
$shadow__sidebar: 0 0 2rem 0 $color__shadow;
$shadow__card: 0 0 1rem 0 $color__shadow;
$shadow__card--active: 0 0 2rem 0 $color__shadow--active;
$shadow__highlight: 0 0 0 0.25rem $color__primary;
$shadow__action: 0.25rem 0.25rem 0.25rem 0 $color__shadow;

$transition: 0.2s ease-in-out;
$transition__header: all 0.6s ease-in-out;

/* rwd spec */
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1399px;
$xxxl: 2560px;

/* brand color */
$color__python: #306998;
$color__git: #f05133;
$color__pygame: #07dc1a;
$color__turtle: #448632;

$num__btn: var(--num__btn);

$spec__header: $xl;

$color: (
  summer: (
    1: var(--color--summer1),
    2: var(--color--summer2),
    3: var(--color--summer3)
  ),
  opacity: (
    1: var(--color__opacity--summer1),
    2: var(--color__opacity--summer2),
    3: var(--color__opacity--summer3),
    summer: (
      1: var(--color__opacity--summer1),
      2: var(--color__opacity--summer2),
      3: var(--color__opacity--summer3)
    )
  ),
  font: (
    summer: (
      1: var(--color__font--summer1),
      2: var(--color__font--summer2),
      3: var(--color__font--summer3)
    ),
    light: var(--color__font--light),
    text: var(--color__font--text)
  ),
  light: var(--color--light)
);

$shadow: (
  summer: (
    item: 0px 0px 10px 0px rgba(0, 0, 0, 0.15),
    block: 0px 0px 8px 0px rgba(0, 0, 0, 0.15)
  )
);
